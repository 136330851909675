import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "@mdi/font/css/materialdesignicons.css";
import CKEditor from "@ckeditor/ckeditor5-vue";
import { initializePushNotifications } from "@/helpers/push-notification.helper";
import { authenticateAgainstKeycloak } from "@/helpers/keycloak.helper";
await initializePushNotifications();
import DateFnsAdapter from "@date-io/date-fns";
import { enUS, de as deDE } from "date-fns/locale";
import { en, de } from "vuetify/locale";

const vuetify = createVuetify({
  date: {
    adapter: DateFnsAdapter,
    locale: {
      en: enUS,
      de: deDE,
    },
  },
  locale: {
    locale: "de",
    fallback: "en",
    messages: { de, en },
  },
  components,
  directives,
});

function instantiateVueApp() {
  createApp(App)
    .use(store)
    .use(router)
    .use(vuetify)
    .use(CKEditor)
    .mount("#app");
}

const token = localStorage.getItem("token");

if (!token) {
  await authenticateAgainstKeycloak();
  instantiateVueApp();
} else {
  instantiateVueApp();
}
