import Keycloak from "keycloak-js";
import router from "@/router";
import store from "@/store";

export const initOptions = {
  realm: "DGAV-User",
  url: process.env.VUE_APP_AUTH_URL,
  clientId: "LearningApp",
  "auth-server-url": process.env.VUE_APP_AUTH_URL,
  "ssl-required": "external",
  resource: "LearningApp",
  "public-client": true,
  "verify-token-audience": true,
  "use-resource-role-mappings": true,
  "confidential-port": 0,
  onLoad: "check-sso",
  checkLoginIframe: true,
  checkLoginIframeInterval: 5,
};

export async function initKeycloak() {
  const keycloak = new Keycloak(initOptions);
  const token = localStorage.getItem("token") || "";
  const idToken = localStorage.getItem("idToken") || "";

  await keycloak
    .init({
      onLoad: "login-required",
      checkLoginIframe: false,
      scope: "openid offline_access",
      token,
      idToken,
    })
    .then((auth) => {
      if (!auth) {
        window.location.reload();
      }

      if (keycloak.token) {
        localStorage.setItem("token", keycloak.token);
      }
      if (keycloak.idToken) {
        localStorage.setItem("idToken", keycloak.idToken || "");
      }
      if (keycloak.refreshToken) {
        localStorage.setItem("refreshToken", keycloak.refreshToken);
      }
      store.commit("setKeycloak", keycloak);
    });
}
export async function authenticateAgainstKeycloak(): Promise<void> {
  await initKeycloak();
  await router.push("/");
}
