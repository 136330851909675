import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/aufgabenliste",
    name: "TodoList",
    component: () =>
      import(/* webpackChunkName: "todolist" */ "@/views/TodoList.vue"),
  },
  {
    path: "/eingriffe",
    name: "SurgeryCatalog",
    component: () =>
      import(
        /* webpackChunkName: "surgery-catalog" */ "@/views/SurgeryCatalog.vue"
      ),
  },
  {
    path: "/favoriten",
    name: "Favorites",
    component: () =>
      import(
        /* webpackChunkName: "favorites-list" */ "@/views/FavoritesList.vue"
      ),
  },
  {
    path: "/nachrichten",
    name: "NotificationList",
    component: () =>
      import(
        /* webpackChunkName: "notification-list" */ "@/views/NotificationList.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  if (!routes.find((route) => route.name === to.name)) {
    next({ name: "SurgeryCatalog", path: "/eingriffe" });
  } else {
    next();
  }
});

export default router;
