export const API_ROUTES = {
  GET_LEARNING_TASKS: "/learning/tasks",
  GET_LEARNING_PLAN: "/learning/plan",
  GET_LEARNING_TASKS_TREE: "/learning/tasks/tree",
  GET_COMPLETION_ITEMS: "/report/completion",
  GET_ACCOUNT: "/account",
  SET_ACCOUNT_SETTINGS: "/account/settings",
  GET_TASK_BY_ID: "/task/:taskId",
  UPDATE_PRIORITY: "/task/:taskId/priority",
  UPDATE_REPEATS_FOR_TASK: "/task/:taskId/amount",
  UPDATE_NOTES_FOR_TASK: "/task/:taskId/notes",
  UPDATE_REPEATS: "/learning/tasks/repeats",
  UPDATE_VALUE: "/task/:taskId/level",
  DELETE_TASK_HISTORY: "/task/:taskId/history/:entryId",
  UNDO_DELETED_TASK_HISTORY: "task/:taskId/history/:entryId/deleted",
  TOGGLE_FAVORITE: "/task/:taskId/favorite",
};
