<template>
  <svg
    id="Ebene_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 114.27 120.14"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          class="st0"
          d="M0,59.75C0,28.19,25.58,2.61,57.14,2.61c31.56,0,57.14,25.58,57.14,57.14c0,31.56-25.58,57.14-57.14,57.14
			C25.58,116.88,0,91.3,0,59.75"
        />
        <path
          class="st1"
          d="M106.6,59.41c0,27.16-22.02,49.19-49.18,49.19c-27.16,0-49.19-22.02-49.19-49.19
			c0-27.16,22.02-49.18,49.19-49.18C84.58,10.22,106.6,32.24,106.6,59.41"
        />
        <path
          class="st0"
          d="M94.6,92.07c-2.27,2.71-8.48,8.4-8.84,7.96c-2.71-2.82-4.73-6.63-5.59-11.04l-4.59-23.6
			C73.55,54.89,78.94,45,87.63,43.31l16.11-3.13c0,0,2.58,8.6,3.02,14.72c0.44,6.12-0.44,14.08-1.66,20.2
			C103.88,81.22,96.88,89.36,94.6,92.07"
        />
        <path
          class="st0"
          d="M34.14,89.04c-0.84,4.31-2.79,8.05-5.4,10.85c-4.54-2.31-13.11-11.93-14.68-16.31
			c-1.57-4.37-3.67-5.77-5.95-15.74c0,0-0.7-3.85-0.53-13.82l3.45-13.76l15.67,3.05C35.38,45,40.77,54.9,38.73,65.42L34.14,89.04z"
        />
        <path
          class="st0"
          d="M91.98,24.06c-1.57,2.99-5.36,5.09-9.78,5.09h-7.03c-5.82,0-10.55-3.65-10.55-8.15v-5.43
			c0-1.89,0.83-3.63,2.23-5.02c0.47-0.28,3.79,1.14,7.48,2.29c0,0,8.83,3.76,12.16,6.54C86.49,19.37,92.1,23.84,91.98,24.06"
        />
        <path
          class="st0"
          d="M49.75,20.99c0,4.5-4.72,8.15-10.55,8.15h-7.03c-4.44,0-8.23-2.12-9.79-5.11c-0.49-0.94,7.24-5.42,7.24-5.42
			c4.52-3.17,10.93-5.66,10.93-5.66c4.63-1.54,6.85-2.49,7.15-2.23c1.29,1.35,2.05,3.02,2.05,4.83V20.99z"
        />
        <path
          class="st0"
          d="M62.07,104.54c0,1.43-0.07,2.8-0.31,4.05c-0.22,1.15-2.16,0.45-4.24,0.45c-2.09,0-4.64,0.7-4.87-0.47
			c-0.25-1.24-0.31-2.61-0.31-4.03c0-6.33,2.18-11.47,4.87-11.47S62.07,98.2,62.07,104.54"
        />
      </g>
      <g>
        <defs>
          <path
            id="SVGID_1_"
            d="M17.94,59.75c0-21.65,17.55-39.2,39.2-39.2c21.65,0,39.2,17.55,39.2,39.2c0,21.65-17.55,39.2-39.2,39.2
				C35.49,98.95,17.94,81.4,17.94,59.75 M6.28,59.75c0,28.09,22.77,50.86,50.86,50.86c28.09,0,50.86-22.77,50.86-50.86
				S85.23,8.89,57.14,8.89C29.05,8.89,6.28,31.66,6.28,59.75"
          />
        </defs>
        <clipPath id="SVGID_2_">
          <use xlink:href="#SVGID_1_" style="overflow: visible" />
        </clipPath>

        <linearGradient
          id="SVGID_3_"
          gradientUnits="userSpaceOnUse"
          x1="-145.9839"
          y1="313.3112"
          x2="-144.9839"
          y2="313.3112"
          gradientTransform="matrix(0 -101.7185 -101.7185 0 31926.6777 -14738.6533)"
        >
          <stop offset="0" style="stop-color: #004189" />
          <stop offset="0.511" style="stop-color: #009adf" />
          <stop offset="0.7944" style="stop-color: #0082ca" />
          <stop offset="1" style="stop-color: #006db8" />
        </linearGradient>
        <rect x="6.28" y="8.89" class="st2" width="101.72" height="101.72" />
      </g>
      <g>
        <path
          class="st0"
          d="M14.08,61.43c0.28-0.08,0.42-0.12,0.42-0.11c0,0.01-0.12-0.01-0.37-0.07l-2.34-0.54
			c-0.02-0.01-0.04,0-0.04,0.02c-0.01,0.15,0,0.35,0.01,0.61c0.01,0.36,0.02,0.6,0.04,0.74c0,0.02,0.01,0.03,0.05,0.02L14.08,61.43z
			 M10.83,62.4c0.02-0.01,0.04-0.02,0.04-0.04c0.01-0.15,0-0.46-0.02-0.95c-0.01-0.46-0.03-0.76-0.04-0.9
			c0-0.01-0.01-0.02-0.04-0.04c-0.56-0.12-1.11-0.24-1.67-0.37c0-0.01,0-0.06,0.01-0.17c0.01-0.1,0.01-0.19,0.01-0.23
			c0-0.05-0.01-0.14-0.02-0.27c-0.02-0.12-0.02-0.19-0.02-0.2c0-0.01,0.01-0.01,0.05,0c1.01,0.27,2.15,0.56,3.45,0.88
			c0.95,0.22,1.9,0.45,2.86,0.67c0.01,0.01,0.02,0.03,0.03,0.05c0,0.01,0,0.07-0.01,0.2c-0.01,0.13,0,0.22,0,0.27
			c0,0.05,0,0.14,0.02,0.27c0.02,0.14,0.02,0.2,0.02,0.21c0,0.03-0.01,0.05-0.05,0.06c-0.67,0.2-1.6,0.48-2.76,0.85
			c-0.88,0.28-2.01,0.66-3.41,1.13c-0.04,0.01-0.05,0-0.05-0.03c0,0.01,0-0.05,0.01-0.19c0.01-0.14,0-0.23,0-0.3
			c0-0.05,0-0.13-0.02-0.24c-0.02-0.11-0.02-0.17-0.02-0.17c0-0.01,0.01-0.03,0.04-0.04C9.54,62.8,10.08,62.64,10.83,62.4"
        />
        <path
          class="st0"
          d="M15.66,64.29c0.01,0.19,0.01,0.31,0.02,0.37c0.01,0.05,0.02,0.14,0.04,0.26c0.03,0.12,0.04,0.18,0.04,0.19
			c0,0.04-0.02,0.06-0.05,0.06c0.04,0-0.09,0.01-0.36,0.04c-0.31,0.03-0.64,0.07-1.01,0.11c-0.52,0.06-1.07,0.13-1.65,0.21
			c-0.37,0.05-0.8,0.1-1.28,0.18l-0.82,0.11c-0.06,0.01-0.08,0.03-0.08,0.06c0.03,0.3,0.06,0.56,0.09,0.8
			c0.01,0.09,0.04,0.29,0.09,0.61c0.05,0.31,0.09,0.46,0.08,0.45c0,0.03-0.01,0.05-0.04,0.05l-0.21,0.03
			c-0.13,0.01-0.24,0.02-0.31,0.03c-0.05,0.01-0.13,0.02-0.26,0.04L9.78,67.9c-0.04,0-0.05,0-0.05-0.03c0,0.02-0.01-0.18-0.05-0.6
			c-0.04-0.41-0.08-0.68-0.09-0.82c-0.04-0.28-0.09-0.58-0.14-0.9c-0.05-0.32-0.08-0.46-0.08-0.42c-0.01-0.05,0.02-0.07,0.05-0.07
			c-0.03,0,0.11-0.01,0.42-0.04c0.34-0.04,0.71-0.08,1.09-0.13c0.55-0.06,1.1-0.13,1.67-0.21c0.56-0.07,1.1-0.14,1.6-0.22
			c0.37-0.05,0.71-0.09,1.01-0.14c0.27-0.04,0.39-0.06,0.36-0.06C15.63,64.25,15.65,64.27,15.66,64.29"
        />
        <path
          class="st0"
          d="M16.19,67.9c0.03,0.18,0.04,0.3,0.05,0.37c0.01,0.05,0.03,0.14,0.07,0.25c0.04,0.12,0.05,0.18,0.05,0.19
			c0.01,0.04-0.01,0.06-0.05,0.07c0.04-0.01-0.08,0.02-0.35,0.07c-0.3,0.06-0.63,0.13-0.99,0.2c-0.51,0.1-1.05,0.22-1.63,0.35
			c-0.36,0.08-0.78,0.17-1.26,0.29l-0.81,0.18c-0.06,0.01-0.08,0.04-0.07,0.06c0.05,0.29,0.11,0.55,0.16,0.79
			c0.02,0.09,0.06,0.29,0.14,0.59c0.08,0.3,0.12,0.46,0.12,0.45c0.01,0.03,0,0.05-0.04,0.06l-0.21,0.05
			c-0.13,0.02-0.24,0.04-0.3,0.06c-0.05,0.01-0.13,0.03-0.25,0.06l-0.18,0.04c-0.04,0.01-0.05,0-0.06-0.03
			c0,0.02-0.03-0.18-0.1-0.59c-0.08-0.41-0.13-0.67-0.16-0.81c-0.06-0.28-0.14-0.57-0.22-0.89c-0.08-0.31-0.12-0.45-0.12-0.41
			c-0.01-0.04,0.01-0.07,0.05-0.08c-0.02,0.01,0.11-0.02,0.41-0.08c0.34-0.06,0.7-0.14,1.07-0.23c0.55-0.11,1.09-0.23,1.64-0.35
			c0.55-0.12,1.09-0.24,1.58-0.35c0.36-0.08,0.7-0.15,1-0.23c0.26-0.07,0.38-0.09,0.36-0.09C16.16,67.86,16.19,67.87,16.19,67.9"
        />
        <path
          class="st0"
          d="M14.29,74.41l0.46-0.15c0.1-0.04,0.15-0.06,0.16-0.06c0.04-0.01,0.06,0,0.08,0.04c0-0.01,0.02,0.07,0.07,0.26
			c0.05,0.19,0.1,0.35,0.15,0.49c0.05,0.14,0.1,0.31,0.18,0.5c0.08,0.2,0.11,0.29,0.1,0.28c0.01,0.03-0.01,0.05-0.07,0.07
			c0.02-0.01-0.12,0.04-0.41,0.13c-0.3,0.09-0.53,0.17-0.71,0.22c-0.25,0.08-0.56,0.19-0.93,0.32c-0.37,0.13-0.55,0.19-0.54,0.19
			c-0.06,0.02-0.11,0.03-0.13,0.01c-0.64-0.33-1.07-0.82-1.28-1.44c-0.22-0.67-0.12-1.3,0.3-1.9c0.43-0.62,1.13-1.09,2.12-1.42
			c1-0.33,1.87-0.35,2.61-0.07c0.67,0.26,1.1,0.69,1.3,1.3c0.19,0.57,0.17,1.16-0.06,1.76c-0.01,0.03-0.05,0.05-0.09,0.05
			c-0.26-0.04-0.57-0.07-0.95-0.07c-0.01,0-0.03-0.01-0.03-0.02c0-0.01,0-0.03,0.01-0.04c0.27-0.53,0.33-1,0.2-1.41
			c-0.14-0.41-0.45-0.68-0.94-0.81c-0.5-0.13-1.04-0.09-1.66,0.11c-0.76,0.25-1.31,0.6-1.64,1.05c-0.29,0.4-0.36,0.8-0.22,1.21
			c0.11,0.33,0.3,0.59,0.57,0.79c0.01,0.01,0.03,0.01,0.05,0c0.17-0.04,0.41-0.13,0.75-0.24c0.13-0.04,0.3-0.1,0.52-0.18
			c0.02-0.01,0.04-0.02,0.07-0.06c-0.04-0.16-0.08-0.28-0.1-0.36c-0.05-0.15-0.16-0.4-0.17-0.44c-0.01-0.02,0-0.03,0.04-0.04
			C14.11,74.45,14.17,74.44,14.29,74.41"
        />
        <path
          class="st0"
          d="M14.67,80.27c0.14,0.27,0.21,0.43,0.23,0.48c0.01,0.03,0,0.04-0.02,0.05l-0.13,0.06
			c-0.1,0.04-0.18,0.07-0.25,0.1c-0.04,0.02-0.14,0.06-0.26,0.13l-0.19,0.09c-0.02,0.01-0.04,0.01-0.05-0.02
			c0.02,0.05-0.06-0.16-0.25-0.61c-0.19-0.45-0.35-0.83-0.48-1.11c-0.04-0.09-0.13-0.26-0.24-0.48c-0.11-0.22-0.17-0.34-0.18-0.36
			c0-0.01,0.01-0.03,0.05-0.04c-0.02,0.01,0.1-0.04,0.39-0.16c0.33-0.14,0.65-0.29,1-0.44c0.51-0.22,1.01-0.45,1.53-0.68
			c0.52-0.23,1.01-0.46,1.47-0.68c0.33-0.15,0.64-0.29,0.92-0.43c0.26-0.13,0.37-0.18,0.34-0.17c0.03-0.02,0.06-0.01,0.08,0.02
			c0-0.01,0.04,0.08,0.11,0.28c0.08,0.2,0.15,0.36,0.21,0.49c0.16,0.35,0.3,0.67,0.45,0.96c0.09,0.2,0.18,0.39,0.26,0.54
			c0.08,0.14,0.1,0.2,0.1,0.18c0.01,0.02,0,0.03-0.03,0.04l-0.16,0.07c-0.11,0.04-0.2,0.08-0.26,0.11
			c-0.07,0.03-0.15,0.07-0.22,0.11l-0.15,0.07c-0.02,0.01-0.04,0.01-0.05-0.02c-0.03-0.06-0.12-0.26-0.26-0.6
			c-0.14-0.33-0.23-0.53-0.25-0.58c-0.1-0.21-0.17-0.38-0.24-0.49c-0.01-0.03-0.04-0.03-0.07-0.02c-0.25,0.1-0.48,0.21-0.68,0.3
			c-0.24,0.11-0.47,0.21-0.73,0.34c-0.03,0.01-0.05,0.03-0.04,0.06c0.08,0.22,0.17,0.4,0.24,0.56c0.09,0.2,0.18,0.4,0.28,0.59
			c0.1,0.2,0.16,0.3,0.17,0.31c0.01,0.02,0,0.04-0.03,0.05l-0.17,0.08c-0.1,0.04-0.18,0.07-0.24,0.1c-0.06,0.02-0.13,0.06-0.21,0.1
			l-0.15,0.07c-0.03,0.02-0.06,0.02-0.07-0.01c-0.21-0.52-0.35-0.87-0.42-1.01c-0.05-0.11-0.13-0.26-0.24-0.46
			c-0.01-0.02-0.04-0.03-0.07-0.02c-0.29,0.12-0.55,0.24-0.8,0.35c-0.28,0.13-0.54,0.24-0.79,0.37c-0.03,0.01-0.04,0.04-0.03,0.06
			c0.09,0.22,0.2,0.47,0.32,0.73C14.45,79.82,14.54,80.01,14.67,80.27"
        />
        <path
          class="st0"
          d="M17.05,86.48c-0.02-0.02,0-0.05,0.05-0.08c0.23-0.15,0.95-0.63,2.14-1.44l1.93-1.31
			c0.35-0.25,0.22-0.16-0.11-0.04c-1.52,0.51-3.07,1.07-4.68,1.66c-0.04,0.01-0.07,0.01-0.09-0.02c-0.01-0.01-0.04-0.06-0.09-0.17
			c-0.05-0.11-0.09-0.17-0.11-0.2c-0.05-0.09-0.19-0.26-0.19-0.27c-0.01-0.02-0.01-0.05,0.01-0.07l1.76-1.7
			c0.47-0.44,1.07-1.04,1.81-1.76c0.05-0.04,0.11-0.1,0.21-0.17c0.08-0.06,0.12-0.09,0.11-0.1l-0.28,0.15
			c-1.24,0.65-2.69,1.44-4.35,2.37c-0.03,0.02-0.05,0.01-0.07-0.02c0,0.01-0.1-0.21-0.16-0.31c-0.04-0.07-0.21-0.3-0.22-0.31
			c-0.02-0.03-0.01-0.06,0.04-0.08l2.77-1.43c1.12-0.58,2.02-1.08,2.7-1.49c0.03-0.02,0.07-0.01,0.09,0.03
			c0.03,0.06,0.16,0.31,0.23,0.42c0.04,0.07,0.11,0.17,0.2,0.3c0.09,0.13,0.14,0.2,0.14,0.21c0.03,0.04,0.03,0.07,0,0.1
			c-0.49,0.45-1.03,0.95-1.6,1.5c-0.77,0.74-1.29,1.25-1.55,1.52c-0.13,0.13-0.27,0.26-0.42,0.38c-0.23,0.19-0.24,0.18,0.02,0.06
			c0.17-0.07,0.31-0.13,0.42-0.16c0.54-0.18,1.24-0.42,2.11-0.74c1.12-0.41,1.82-0.67,2.09-0.76c0.04-0.01,0.07-0.01,0.09,0.02
			c0.01,0.02,0.05,0.09,0.12,0.21c0.07,0.13,0.12,0.23,0.17,0.3c0.08,0.13,0.28,0.41,0.32,0.48c0.01,0.02-0.01,0.06-0.06,0.09
			c-0.25,0.16-1.07,0.73-2.46,1.69l-2.6,1.82c-0.04,0.03-0.07,0.03-0.09-0.01c-0.01-0.01-0.04-0.06-0.09-0.17
			c-0.05-0.1-0.09-0.16-0.11-0.2C17.22,86.71,17.07,86.51,17.05,86.48"
        />
        <path
          class="st0"
          d="M20.78,89.87c0.2,0.23,0.31,0.37,0.34,0.41c0.02,0.02,0.01,0.04-0.01,0.05l-0.12,0.09
			c-0.09,0.06-0.16,0.12-0.22,0.16c-0.04,0.03-0.12,0.09-0.22,0.18l-0.16,0.13c-0.02,0.02-0.04,0.02-0.05-0.01
			c0.03,0.04-0.09-0.14-0.39-0.53c-0.29-0.39-0.54-0.72-0.73-0.97c-0.06-0.08-0.19-0.22-0.35-0.41c-0.17-0.19-0.25-0.29-0.26-0.31
			c-0.01-0.01,0.01-0.03,0.04-0.05c-0.02,0.01,0.09-0.07,0.34-0.25c0.29-0.21,0.57-0.44,0.87-0.67c0.44-0.34,0.88-0.68,1.32-1.03
			c0.45-0.35,0.87-0.69,1.26-1.01c0.28-0.22,0.55-0.43,0.79-0.63c0.22-0.18,0.31-0.26,0.29-0.24c0.03-0.02,0.06-0.02,0.08,0
			c-0.01-0.01,0.06,0.07,0.18,0.25c0.12,0.18,0.23,0.31,0.32,0.42c0.24,0.3,0.46,0.58,0.66,0.82c0.14,0.18,0.26,0.33,0.38,0.46
			c0.11,0.12,0.15,0.17,0.13,0.15c0.01,0.01,0.01,0.03-0.02,0.05l-0.14,0.11c-0.09,0.06-0.17,0.12-0.23,0.17
			c-0.06,0.05-0.13,0.1-0.19,0.16l-0.13,0.1c-0.02,0.01-0.04,0.02-0.05-0.01c-0.04-0.05-0.18-0.23-0.4-0.52
			c-0.21-0.29-0.35-0.46-0.38-0.5c-0.14-0.18-0.26-0.33-0.35-0.42c-0.02-0.02-0.05-0.02-0.08,0c-0.22,0.16-0.41,0.31-0.59,0.45
			c-0.2,0.16-0.41,0.32-0.62,0.5c-0.03,0.02-0.04,0.04-0.03,0.07c0.13,0.19,0.26,0.35,0.37,0.49c0.14,0.18,0.28,0.35,0.42,0.51
			c0.15,0.17,0.23,0.25,0.24,0.26c0.02,0.02,0.01,0.04-0.02,0.06l-0.15,0.12c-0.09,0.06-0.16,0.12-0.21,0.15
			c-0.05,0.04-0.12,0.09-0.18,0.15l-0.13,0.1c-0.03,0.02-0.05,0.03-0.07,0.01c-0.33-0.46-0.55-0.76-0.65-0.88
			c-0.07-0.1-0.19-0.22-0.34-0.39c-0.02-0.02-0.05-0.02-0.08,0c-0.25,0.18-0.48,0.37-0.69,0.54c-0.24,0.19-0.46,0.37-0.68,0.55
			c-0.03,0.02-0.03,0.04-0.01,0.07c0.14,0.2,0.31,0.41,0.48,0.63C20.46,89.48,20.59,89.64,20.78,89.87"
        />
        <path
          class="st0"
          d="M21.93,91.93c0.27-0.24,0.53-0.48,0.81-0.74c0.41-0.37,0.82-0.75,1.24-1.13c0.42-0.39,0.82-0.75,1.18-1.1
			c0.26-0.24,0.51-0.47,0.73-0.68c0.19-0.19,0.28-0.27,0.27-0.27c0.04-0.03,0.07-0.04,0.09-0.02c0.12,0.14,0.19,0.24,0.23,0.29
			c0.04,0.04,0.09,0.1,0.18,0.17c0.09,0.08,0.14,0.13,0.14,0.14c0.02,0.03,0.03,0.05,0,0.07c0.06-0.06-0.15,0.13-0.64,0.56
			c-0.49,0.44-1.02,0.93-1.62,1.48c-0.44,0.41-0.86,0.79-1.25,1.17c-0.28,0.26-0.54,0.49-0.77,0.73c-0.2,0.2-0.29,0.28-0.28,0.27
			c-0.03,0.03-0.05,0.04-0.06,0.02c-0.16-0.19-0.25-0.31-0.28-0.34c-0.06-0.06-0.28-0.27-0.31-0.3c-0.01-0.01,0-0.03,0.03-0.05
			C21.6,92.22,21.7,92.13,21.93,91.93"
        />
        <path
          class="st0"
          d="M27.16,95.33c0.65-0.72,1.33-1.49,2.04-2.3c0.71-0.8,1.03-1.18,0.98-1.11c0.04-0.05,0.07-0.05,0.1-0.03
			c0.01,0.01,0.17,0.18,0.24,0.25c0.08,0.07,0.26,0.2,0.3,0.24c0.02,0.02,0.02,0.05-0.01,0.09c0.07-0.07-0.12,0.12-0.57,0.6
			c-0.44,0.47-0.92,1.01-1.48,1.63c-0.55,0.61-1.06,1.18-1.51,1.69c-0.45,0.51-0.64,0.74-0.58,0.67c-0.03,0.04-0.06,0.04-0.09,0.02
			c-0.05-0.05-0.21-0.22-0.35-0.35c-0.03-0.03-0.09-0.08-0.19-0.16c-0.1-0.07-0.17-0.12-0.19-0.14c-0.03-0.02-0.04-0.05-0.03-0.09
			c0.14-0.39,0.45-1.32,0.92-2.79c0.25-0.79,0.43-1.38,0.55-1.78c0.04-0.15,0.1-0.29,0.17-0.43c0.09-0.21,0.16-0.25-0.2,0.15
			c-0.81,0.9-1.51,1.7-2.12,2.39c-0.6,0.68-0.89,1.02-0.88,1.01c-0.03,0.03-0.05,0.04-0.07,0.02c-0.01-0.01-0.05-0.04-0.12-0.13
			c-0.07-0.08-0.12-0.13-0.14-0.15c-0.08-0.07-0.29-0.23-0.3-0.24c-0.02-0.02-0.02-0.03,0.01-0.06c-0.03,0.04,0.18-0.2,0.66-0.72
			c0.48-0.52,0.99-1.07,1.51-1.65c0.52-0.58,0.99-1.1,1.41-1.58c0.43-0.49,0.63-0.71,0.6-0.68c0.02-0.03,0.05-0.02,0.07-0.01
			c0.01,0.01,0.23,0.24,0.35,0.35c0.06,0.05,0.13,0.12,0.24,0.2c0.11,0.08,0.17,0.12,0.18,0.13c0.01,0.01,0.02,0.04,0.01,0.07
			c-0.08,0.23-0.34,1.02-0.77,2.39l-0.7,2.24c-0.04,0.11-0.09,0.22-0.16,0.34c-0.05,0.09-0.07,0.13-0.07,0.13L27.16,95.33z"
        />
        <path
          class="st0"
          d="M30.29,95.02c-0.01,0,0.07,0.06,0.25,0.2c0.18,0.15,0.34,0.27,0.5,0.38c0.19,0.14,0.39,0.28,0.63,0.44
			c0.24,0.16,0.35,0.24,0.33,0.23c0.04,0.03,0.05,0.07,0.02,0.12l-0.11,0.15c-0.07,0.08-0.13,0.16-0.17,0.21
			c-0.04,0.05-0.08,0.11-0.13,0.19l-0.13,0.18c-0.02,0.03-0.05,0.04-0.09,0.01c0.02,0.02-0.08-0.06-0.31-0.24
			c-0.23-0.18-0.43-0.33-0.61-0.46l-0.51-0.38c-0.19-0.13-0.28-0.19-0.26-0.18c-0.04-0.03-0.04-0.06-0.02-0.09
			c0.01-0.01,0.04-0.06,0.12-0.15l0.31-0.42c0.05-0.08,0.08-0.12,0.09-0.13C30.22,95.02,30.26,95,30.29,95.02"
        />
        <path
          class="st0"
          d="M36.91,98.96c0.07-0.16,0.23-0.52,0.49-1.08c0.26-0.57,0.38-0.84,0.38-0.83c0.02-0.03,0.03-0.04,0.05-0.03
			c0.01,0,0.06,0.03,0.15,0.09c0.1,0.06,0.18,0.1,0.23,0.12c0.04,0.02,0.11,0.05,0.21,0.09c0.11,0.04,0.17,0.06,0.17,0.07
			s0.01,0.03-0.01,0.06c0.02-0.04-0.09,0.16-0.32,0.64c-0.23,0.46-0.43,0.89-0.61,1.27c-0.39,0.84-0.67,1.43-0.81,1.76
			c-0.38,0.86-0.28,1.42,0.31,1.7c0.64,0.3,1.17,0.02,1.6-0.86c0.14-0.27,0.41-0.85,0.81-1.71c0.18-0.38,0.38-0.82,0.59-1.3
			c0.22-0.49,0.32-0.7,0.29-0.64c0.02-0.03,0.03-0.04,0.05-0.03c0.19,0.1,0.3,0.17,0.34,0.19c0.1,0.05,0.38,0.15,0.4,0.16
			c0.01,0,0.01,0.02,0,0.05c0.01-0.02-0.12,0.24-0.4,0.81c-0.27,0.56-0.44,0.91-0.52,1.08l-0.76,1.62
			c-0.37,0.79-0.82,1.32-1.35,1.56c-0.47,0.2-0.97,0.19-1.49-0.06c-1.11-0.52-1.31-1.51-0.63-2.98L36.91,98.96z"
        />
        <path
          class="st0"
          d="M44.04,104.5c0.3-0.93,0.61-1.9,0.93-2.93c0.32-1.02,0.47-1.5,0.44-1.41c0.02-0.06,0.04-0.08,0.08-0.06
			c0.01,0,0.23,0.1,0.33,0.13s0.32,0.08,0.37,0.09c0.03,0.01,0.04,0.04,0.02,0.09c0.03-0.09-0.06,0.16-0.27,0.78
			c-0.21,0.61-0.43,1.3-0.69,2.08c-0.26,0.79-0.49,1.51-0.7,2.16c-0.2,0.65-0.28,0.93-0.26,0.85c-0.02,0.05-0.04,0.06-0.08,0.05
			c-0.07-0.02-0.28-0.12-0.47-0.18c-0.04-0.01-0.12-0.04-0.24-0.07c-0.12-0.03-0.2-0.04-0.23-0.05c-0.04-0.01-0.06-0.03-0.06-0.07
			c-0.03-0.41-0.12-1.39-0.29-2.92c-0.09-0.82-0.16-1.44-0.21-1.85c-0.02-0.15-0.03-0.31-0.02-0.46c-0.01-0.22,0.05-0.3-0.12,0.22
			c-0.38,1.15-0.7,2.16-0.97,3.04c-0.27,0.87-0.4,1.3-0.4,1.28c-0.01,0.04-0.03,0.06-0.05,0.05c-0.01,0-0.06-0.02-0.16-0.07
			c-0.09-0.04-0.16-0.07-0.19-0.07c-0.1-0.03-0.36-0.09-0.37-0.09c-0.03-0.01-0.03-0.02-0.02-0.06c-0.01,0.05,0.08-0.26,0.31-0.92
			c0.23-0.67,0.47-1.38,0.71-2.12s0.46-1.41,0.65-2.02c0.19-0.62,0.29-0.91,0.27-0.86c0.01-0.03,0.03-0.04,0.06-0.03
			c0.01,0,0.31,0.13,0.46,0.18c0.07,0.02,0.17,0.05,0.3,0.09s0.21,0.04,0.22,0.04c0.02,0.01,0.03,0.02,0.04,0.07
			c0.02,0.24,0.11,1.07,0.26,2.5l0.26,2.33c0.01,0.12,0.01,0.24-0.01,0.38c0,0.1-0.01,0.15-0.01,0.15L44.04,104.5z"
        />
        <path
          class="st0"
          d="M49.81,104.23c0.13-0.65,0.1-1.2-0.11-1.64c-0.18-0.39-0.54-0.63-1.07-0.74c-0.17-0.04-0.33-0.07-0.47-0.08
			c-0.03-0.01-0.05,0.02-0.06,0.05l-0.18,0.9c-0.11,0.51-0.19,0.91-0.25,1.21c-0.06,0.28-0.13,0.65-0.22,1.15l-0.17,0.87
			c-0.01,0.05,0,0.08,0.03,0.08c0.24,0.06,0.49,0.12,0.73,0.17C48.94,106.37,49.52,105.71,49.81,104.23 M50.47,101.97
			c0.32,0.63,0.39,1.4,0.21,2.33c-0.42,2.14-1.43,3.15-2.87,2.87c-0.06-0.01-0.22-0.05-0.46-0.12c-0.24-0.06-0.43-0.1-0.56-0.12
			c-0.1-0.02-0.38-0.05-0.52-0.08c-0.03-0.01-0.04-0.03-0.03-0.08c-0.01,0.02,0.06-0.33,0.21-1.04c0.15-0.72,0.31-1.5,0.48-2.32
			c0.15-0.75,0.27-1.41,0.36-1.95c0.1-0.55,0.15-0.8,0.14-0.74c0.01-0.04,0.03-0.06,0.06-0.05c0.77,0.13,1.22,0.2,1.37,0.23
			C49.64,101.05,50.18,101.41,50.47,101.97"
        />
        <path
          class="st0"
          d="M57.69,106.59c0.02-0.16,0.04-0.31,0.08-0.44c0.09-0.36,0.23-0.97,0.45-1.83c0.28-1.15,0.48-2.01,0.59-2.54
			c0.01-0.02,0.03-0.04,0.04-0.04l0.17,0c0.1,0.01,0.19,0.01,0.23,0.01c0.06,0,0.14,0,0.26-0.02l0.17,0c0.03,0,0.04,0.01,0.03,0.05
			c-0.16,0.58-0.41,1.55-0.75,2.88c-0.42,1.63-0.7,2.76-0.82,3.38c-0.01,0.04-0.02,0.05-0.04,0.05l-0.17,0
			c-0.11-0.01-0.2-0.01-0.24-0.01c-0.05,0-0.13,0-0.24,0.02c-0.22,0-0.2,0.01-0.21-0.05c-0.74-2.68-1.34-4.74-1.79-6.18
			c-0.01-0.05,0-0.07,0.03-0.07c0.27,0.01,0.43,0.02,0.49,0.02c0.1,0,0.37-0.03,0.42-0.03c0.02,0,0.03,0.01,0.04,0.04
			c0.13,0.53,0.33,1.29,0.59,2.31c0.23,0.9,0.41,1.56,0.54,2.01c0.05,0.16,0.08,0.31,0.11,0.47c0.01,0.11,0.02,0.16,0.03,0.16
			C57.67,106.76,57.68,106.7,57.69,106.59"
        />
        <path
          class="st0"
          d="M61.03,107.46c-0.02-0.36-0.05-0.71-0.09-1.09c-0.04-0.55-0.09-1.11-0.14-1.67c-0.05-0.56-0.1-1.11-0.15-1.61
			c-0.03-0.36-0.06-0.69-0.1-0.99c-0.03-0.27-0.04-0.39-0.04-0.38c0-0.05,0.01-0.08,0.04-0.08c0.18,0,0.31,0,0.37-0.01
			c0.05-0.01,0.14-0.01,0.25-0.03c0.12-0.02,0.18-0.03,0.19-0.03c0.04,0,0.06,0.01,0.06,0.04c-0.01-0.09,0.01,0.2,0.06,0.85
			c0.05,0.65,0.11,1.38,0.18,2.19c0.05,0.6,0.1,1.17,0.16,1.71c0.03,0.38,0.06,0.73,0.1,1.06c0.03,0.28,0.04,0.4,0.04,0.39
			c0,0.04,0,0.06-0.02,0.06c-0.25,0.01-0.4,0.01-0.44,0.01c-0.08,0.01-0.38,0.06-0.42,0.06c-0.01,0-0.02-0.02-0.02-0.06
			C61.05,107.9,61.04,107.76,61.03,107.46"
        />
        <path
          class="st0"
          d="M65.28,103.94c0.31,0.14,0.57,0.28,0.76,0.46c0.28,0.25,0.45,0.59,0.51,0.99c0.11,0.64,0.01,1.16-0.27,1.57
			c-0.25,0.35-0.62,0.57-1.09,0.64c-0.61,0.1-1.15,0.04-1.63-0.19c-0.03-0.01-0.04-0.04-0.04-0.11c0.03-0.12,0.06-0.41,0.12-0.91
			c0.01-0.06,0.04-0.07,0.09-0.04c0.46,0.26,0.9,0.36,1.31,0.3c0.26-0.04,0.45-0.16,0.57-0.36c0.11-0.17,0.14-0.37,0.1-0.62
			c-0.07-0.39-0.34-0.67-0.81-0.85l-0.52-0.2c-0.39-0.15-0.68-0.31-0.87-0.47c-0.33-0.28-0.53-0.66-0.61-1.15
			c-0.07-0.45-0.01-0.86,0.2-1.23c0.24-0.43,0.62-0.68,1.15-0.77c0.5-0.08,0.96-0.01,1.38,0.23c0.02,0.01,0.03,0.04,0.01,0.09
			c-0.04,0.17-0.08,0.48-0.13,0.9c0,0.04-0.03,0.05-0.07,0.02c-0.4-0.26-0.78-0.36-1.15-0.3c-0.43,0.07-0.63,0.44-0.57,0.82
			c0.07,0.39,0.36,0.69,0.89,0.9L65.28,103.94z"
        />
        <path
          class="st0"
          d="M70.01,100.55c0.01,0.04,0.01,0.07,0.01,0.1c-0.28,1.14-0.65,2.77-1.1,4.9c-0.01,0.04,0,0.06,0.03,0.06
			c0.28-0.07,0.71-0.19,1.31-0.37c0.6-0.17,0.95-0.28,1.06-0.31c0.03-0.01,0.04,0,0.05,0.04l0.05,0.19
			c0.03,0.13,0.05,0.23,0.07,0.29c0.03,0.1,0.05,0.2,0.09,0.31l0.04,0.15c0.01,0.04,0,0.05-0.01,0.05c-0.17,0.05-0.6,0.15-1.28,0.32
			c-0.67,0.17-1.13,0.29-1.35,0.35c-0.16,0.04-0.33,0.09-0.51,0.15c-0.18,0.06-0.26,0.1-0.27,0.1c-0.02,0-0.03-0.01-0.04-0.03
			c-0.04-0.12-0.12-0.34-0.22-0.65c-0.01-0.04-0.01-0.06,0-0.08c0.47-1.98,0.84-3.64,1.1-4.99c0.01-0.03,0-0.04-0.03-0.03
			c-0.38,0.1-0.76,0.21-1.12,0.32c-0.26,0.07-0.5,0.14-0.72,0.21c-0.19,0.07-0.28,0.09-0.26,0.08c-0.03,0.01-0.04-0.01-0.05-0.05
			c0,0.01-0.01-0.05-0.04-0.17l-0.08-0.31l-0.07-0.26c-0.04-0.1-0.05-0.16-0.05-0.15c-0.01-0.04,0-0.05,0.02-0.06
			c-0.01,0,0.38-0.09,1.14-0.28c0.76-0.19,1.19-0.31,1.29-0.34c0.04-0.01,0.18-0.05,0.4-0.12c0.22-0.07,0.32-0.11,0.33-0.11
			c0.01,0,0.01,0.01,0.02,0.04C69.84,100.07,69.92,100.28,70.01,100.55"
        />
        <path
          class="st0"
          d="M75.02,103.68c0.28-0.12,0.44-0.18,0.49-0.2c0.03-0.01,0.04,0,0.05,0.02l0.05,0.14
			c0.03,0.11,0.06,0.19,0.09,0.26c0.02,0.05,0.05,0.14,0.11,0.26l0.08,0.2c0.01,0.02,0,0.04-0.02,0.05
			c0.05-0.02-0.16,0.05-0.62,0.21c-0.46,0.16-0.85,0.3-1.14,0.41c-0.1,0.04-0.26,0.11-0.49,0.21c-0.23,0.1-0.35,0.15-0.37,0.15
			c-0.01,0-0.02-0.02-0.04-0.05c0.01,0.02-0.04-0.1-0.14-0.39c-0.12-0.34-0.25-0.67-0.38-1.03c-0.19-0.52-0.39-1.04-0.59-1.57
			c-0.2-0.53-0.4-1.04-0.59-1.51c-0.13-0.33-0.25-0.66-0.37-0.94c-0.11-0.26-0.15-0.38-0.15-0.35c-0.01-0.03,0-0.06,0.02-0.07
			c-0.01,0,0.09-0.03,0.29-0.1c0.2-0.07,0.37-0.13,0.5-0.18c0.36-0.14,0.69-0.26,0.98-0.39c0.21-0.08,0.4-0.15,0.56-0.23
			c0.14-0.07,0.2-0.09,0.19-0.08c0.02-0.01,0.03,0,0.04,0.04l0.06,0.16c0.03,0.11,0.07,0.2,0.09,0.27c0.03,0.07,0.06,0.15,0.1,0.23
			l0.06,0.15c0.01,0.02,0.01,0.04-0.02,0.05c-0.06,0.02-0.27,0.1-0.62,0.22c-0.34,0.12-0.54,0.19-0.59,0.21
			c-0.22,0.08-0.39,0.15-0.51,0.21c-0.03,0.01-0.04,0.04-0.02,0.07c0.09,0.26,0.18,0.49,0.26,0.7c0.09,0.24,0.19,0.48,0.29,0.75
			c0.01,0.03,0.03,0.05,0.06,0.04c0.22-0.07,0.41-0.14,0.57-0.21c0.21-0.08,0.41-0.16,0.61-0.25c0.2-0.09,0.31-0.15,0.32-0.15
			c0.03-0.01,0.04,0,0.05,0.03l0.07,0.17c0.03,0.11,0.06,0.19,0.08,0.25c0.02,0.06,0.05,0.14,0.09,0.22l0.06,0.15
			c0.01,0.04,0.01,0.06-0.01,0.07c-0.54,0.18-0.89,0.3-1.03,0.35c-0.11,0.04-0.26,0.11-0.48,0.21c-0.03,0.01-0.03,0.04-0.02,0.07
			c0.1,0.29,0.21,0.57,0.3,0.82c0.11,0.29,0.21,0.55,0.32,0.81c0.01,0.04,0.03,0.04,0.06,0.03c0.23-0.07,0.48-0.17,0.75-0.27
			C74.55,103.87,74.75,103.8,75.02,103.68"
        />
        <path
          class="st0"
          d="M77.25,99.82c0.26-0.13,0.41-0.33,0.46-0.59c0.04-0.21,0-0.44-0.13-0.69c-0.26-0.52-0.66-0.65-1.19-0.38
			c-0.26,0.13-0.45,0.22-0.54,0.29c-0.03,0.01-0.03,0.03-0.01,0.06c0.23,0.48,0.49,1.01,0.78,1.57c0.02,0.03,0.03,0.04,0.06,0.03
			C76.78,100.05,76.97,99.96,77.25,99.82 M77.1,100.93c-0.03,0.01-0.02,0.04,0,0.08c0.22,0.44,0.46,0.91,0.72,1.41
			s0.38,0.71,0.35,0.65l0.08,0.17c0.02,0.03,0.01,0.05-0.01,0.06c0.01,0-0.22,0.08-0.35,0.15c-0.11,0.05-0.34,0.2-0.36,0.21
			c-0.01,0-0.02,0-0.04-0.04c0.01,0.01-0.15-0.31-0.48-0.98c-0.32-0.67-0.67-1.38-1.05-2.12c-0.25-0.5-0.48-0.96-0.69-1.36
			c-0.14-0.29-0.28-0.55-0.41-0.79c-0.11-0.21-0.16-0.3-0.16-0.29c-0.02-0.03-0.02-0.06,0-0.07c0.27-0.15,0.66-0.36,1.16-0.61
			c0.51-0.25,0.92-0.35,1.24-0.29c0.5,0.09,0.9,0.43,1.19,1.02c0.35,0.71,0.29,1.4-0.07,1.86c-0.02,0.02-0.01,0.04,0.02,0.04
			c0.25,0.07,0.58,0.32,1,0.73c0.59,0.59,1.04,1.02,1.34,1.3c0.03,0.03,0.04,0.05,0.01,0.06c0.01,0-0.05,0.02-0.17,0.07
			c-0.11,0.04-0.2,0.09-0.27,0.12c-0.12,0.06-0.35,0.2-0.38,0.22c-0.01,0-0.02,0-0.05-0.03c-0.22-0.26-0.62-0.68-1.21-1.26
			c-0.31-0.3-0.53-0.48-0.69-0.53c-0.13-0.03-0.29,0-0.49,0.1L77.1,100.93z"
        />
        <path
          class="st0"
          d="M80.59,96.32c-0.22-0.2-0.31-0.3-0.3-0.3c0.01-0.01,0.05,0.11,0.13,0.36l0.74,2.28
			c0,0.03,0.02,0.03,0.03,0.02c0.13-0.07,0.3-0.18,0.52-0.32c0.3-0.19,0.51-0.32,0.62-0.41c0.01-0.01,0.02-0.03-0.01-0.05
			L80.59,96.32z M83.1,98.62c-0.02-0.02-0.04-0.02-0.05-0.01c-0.13,0.07-0.4,0.24-0.81,0.5c-0.39,0.25-0.64,0.41-0.75,0.49
			c-0.01,0.01-0.01,0.02-0.01,0.05c0.18,0.54,0.36,1.07,0.53,1.62c-0.01,0-0.05,0.03-0.15,0.08c-0.09,0.04-0.16,0.09-0.2,0.11
			c-0.04,0.02-0.12,0.07-0.22,0.15c-0.09,0.08-0.16,0.12-0.16,0.12c-0.01,0.01-0.01-0.01-0.03-0.04c-0.28-1.01-0.62-2.14-1.01-3.41
			c-0.29-0.92-0.59-1.86-0.88-2.8c0-0.01,0.01-0.04,0.03-0.04c0.01-0.01,0.06-0.04,0.18-0.1c0.12-0.06,0.19-0.11,0.23-0.13
			c0.04-0.03,0.12-0.07,0.22-0.15c0.11-0.09,0.17-0.12,0.17-0.12c0.02-0.01,0.04-0.01,0.07,0.01c0.51,0.48,1.23,1.13,2.14,1.94
			c0.69,0.61,1.6,1.4,2.71,2.35c0.03,0.03,0.03,0.04,0,0.06c0.01-0.01-0.05,0.03-0.17,0.09c-0.12,0.07-0.2,0.11-0.26,0.15
			c-0.04,0.03-0.11,0.07-0.2,0.14c-0.09,0.07-0.13,0.1-0.14,0.11c-0.01,0.01-0.03,0-0.05-0.01C84.09,99.52,83.68,99.14,83.1,98.62"
        />
        <path
          class="st0"
          d="M82.25,93.49c0.15-0.1,0.25-0.17,0.31-0.21c0.04-0.03,0.11-0.09,0.2-0.17c0.09-0.08,0.14-0.12,0.14-0.13
			c0.03-0.02,0.06-0.01,0.08,0.01c-0.02-0.03,0.05,0.07,0.21,0.29c0.18,0.25,0.39,0.51,0.61,0.81c0.31,0.41,0.66,0.85,1.02,1.32
			c0.23,0.29,0.49,0.63,0.81,1.01l0.51,0.65c0.04,0.05,0.07,0.06,0.09,0.04c0.24-0.17,0.45-0.34,0.64-0.49
			c0.07-0.06,0.23-0.18,0.47-0.39c0.24-0.2,0.36-0.31,0.35-0.31c0.02-0.02,0.04-0.02,0.07,0.01l0.13,0.17
			c0.08,0.11,0.15,0.2,0.18,0.25c0.03,0.04,0.08,0.11,0.17,0.2l0.11,0.15c0.02,0.03,0.02,0.04,0,0.06c0.01-0.01-0.15,0.1-0.49,0.35
			c-0.33,0.25-0.55,0.41-0.66,0.5c-0.23,0.18-0.46,0.38-0.71,0.58c-0.25,0.21-0.35,0.31-0.32,0.28c-0.04,0.03-0.07,0.02-0.09-0.01
			c0.01,0.02-0.07-0.09-0.25-0.33c-0.21-0.28-0.43-0.57-0.67-0.87c-0.34-0.44-0.68-0.88-1.03-1.32c-0.35-0.45-0.69-0.87-1.01-1.26
			c-0.23-0.29-0.44-0.56-0.64-0.8c-0.18-0.21-0.25-0.3-0.24-0.29C82.23,93.54,82.23,93.51,82.25,93.49"
        />
        <path
          class="st0"
          d="M91.6,93.26c-0.11,0.58-0.37,1.06-0.77,1.45c-0.46,0.44-1.04,0.6-1.74,0.46c-0.73-0.14-1.45-0.58-2.15-1.31
			c-0.74-0.78-1.15-1.56-1.21-2.34c-0.07-0.7,0.15-1.3,0.64-1.76c0.39-0.37,0.84-0.57,1.35-0.6c0.04-0.01,0.08,0.01,0.09,0.05
			c0.13,0.37,0.26,0.66,0.36,0.86c0.02,0.03,0.01,0.06-0.03,0.06c-0.51,0.01-0.9,0.14-1.17,0.4c-0.28,0.27-0.4,0.62-0.35,1.06
			c0.06,0.51,0.35,1.03,0.86,1.57c0.52,0.54,1.03,0.87,1.55,0.99c0.5,0.12,0.9,0.03,1.2-0.26c0.18-0.17,0.34-0.53,0.47-1.05
			c0-0.04,0.03-0.05,0.06-0.03c0.21,0.12,0.48,0.26,0.81,0.39C91.59,93.22,91.61,93.23,91.6,93.26"
        />
        <path
          class="st0"
          d="M91.94,87.6c-0.43-0.36-0.86-0.72-1.31-1.09s-0.65-0.53-0.59-0.49c-0.03-0.02-0.03-0.04-0.02-0.05
			c0.01-0.01,0.04-0.04,0.11-0.11c0.08-0.07,0.13-0.13,0.16-0.17c0.03-0.03,0.08-0.1,0.15-0.2c0.07-0.1,0.11-0.15,0.12-0.16
			c0.01-0.01,0.03-0.01,0.05,0c-0.02-0.02,0.07,0.06,0.28,0.25c0.23,0.21,0.49,0.42,0.77,0.66c0.4,0.35,0.82,0.71,1.28,1.09
			c0.46,0.39,0.9,0.75,1.31,1.09c0.28,0.24,0.56,0.47,0.82,0.68c0.22,0.17,0.35,0.28,0.36,0.29c0.01,0.01,0,0.01-0.01,0.03
			c-0.16,0.17-0.26,0.27-0.29,0.3c-0.03,0.04-0.08,0.1-0.15,0.2c-0.07,0.1-0.11,0.15-0.12,0.16c-0.01,0.01-0.01,0-0.04-0.03
			c0.05,0.04-0.17-0.14-0.64-0.55c-0.47-0.4-0.95-0.81-1.45-1.23c-0.02-0.02-0.03-0.02-0.04-0.01c-0.26,0.3-0.51,0.56-0.71,0.81
			c-0.19,0.22-0.41,0.5-0.68,0.85c-0.01,0.01-0.01,0.03,0.01,0.04c0.48,0.4,0.97,0.82,1.48,1.23c0.51,0.41,0.71,0.59,0.64,0.52
			c0.02,0.02,0.02,0.03,0.01,0.04c-0.16,0.18-0.26,0.28-0.29,0.31c-0.03,0.04-0.08,0.1-0.15,0.19c-0.06,0.09-0.1,0.14-0.1,0.14
			c-0.01,0.01-0.03,0.01-0.06-0.01c0.01,0.01-0.09-0.08-0.32-0.28c-0.27-0.24-0.55-0.48-0.84-0.72c-0.41-0.36-0.83-0.71-1.27-1.08
			c-0.6-0.5-1.14-0.95-1.64-1.35c-0.51-0.41-0.73-0.6-0.69-0.57c-0.03-0.02-0.03-0.04-0.01-0.06c0.02-0.02,0.21-0.21,0.26-0.28
			c0.03-0.03,0.08-0.09,0.15-0.19c0.07-0.1,0.11-0.15,0.12-0.16c0.02-0.02,0.03-0.02,0.06,0c0.01,0.01,0.23,0.19,0.65,0.55
			c0.42,0.37,0.83,0.71,1.23,1.05c0.02,0.01,0.04,0.02,0.05,0c0.29-0.32,0.53-0.59,0.7-0.8c0.17-0.2,0.39-0.48,0.68-0.85
			C91.96,87.63,91.96,87.62,91.94,87.6"
        />
        <path
          class="st0"
          d="M96.21,87.45c-0.29-0.21-0.58-0.42-0.9-0.63c-0.45-0.32-0.91-0.64-1.37-0.96c-0.47-0.32-0.91-0.63-1.33-0.91
			c-0.29-0.2-0.57-0.39-0.83-0.56c-0.23-0.15-0.33-0.22-0.32-0.21c-0.04-0.03-0.05-0.06-0.04-0.08c0.12-0.14,0.19-0.24,0.23-0.29
			c0.03-0.04,0.08-0.11,0.13-0.21c0.06-0.11,0.1-0.16,0.1-0.17c0.02-0.03,0.04-0.04,0.07-0.02c-0.07-0.05,0.16,0.12,0.69,0.5
			c0.53,0.38,1.13,0.79,1.8,1.26c0.5,0.34,0.96,0.67,1.41,0.97c0.31,0.22,0.6,0.42,0.88,0.6c0.24,0.15,0.34,0.22,0.33,0.22
			c0.03,0.02,0.04,0.04,0.03,0.06c-0.15,0.2-0.25,0.31-0.27,0.35c-0.05,0.07-0.2,0.33-0.22,0.36c-0.01,0.01-0.03,0-0.06-0.02
			C96.57,87.71,96.46,87.63,96.21,87.45"
        />
        <path
          class="st0"
          d="M95.92,82.21c0.14-0.25,0.16-0.5,0.06-0.74c-0.09-0.2-0.24-0.37-0.49-0.51c-0.5-0.29-0.91-0.18-1.21,0.34
			c-0.15,0.26-0.25,0.43-0.29,0.54c-0.01,0.02-0.01,0.04,0.02,0.06c0.45,0.27,0.97,0.57,1.52,0.88c0.03,0.02,0.05,0.02,0.06-0.01
			C95.65,82.67,95.76,82.49,95.92,82.21 M96.4,83.23c-0.01,0.02,0,0.04,0.05,0.07c0.43,0.25,0.89,0.51,1.37,0.78
			c0.49,0.27,0.7,0.39,0.65,0.35l0.16,0.09c0.03,0.02,0.04,0.03,0.02,0.06c0-0.01-0.14,0.19-0.21,0.32
			c-0.06,0.1-0.17,0.35-0.19,0.37c0,0.01-0.02,0.01-0.05-0.01c0.01,0.01-0.3-0.17-0.93-0.55c-0.64-0.38-1.32-0.78-2.04-1.2
			c-0.48-0.28-0.93-0.54-1.33-0.76c-0.28-0.16-0.53-0.31-0.77-0.44c-0.21-0.11-0.3-0.17-0.29-0.16c-0.03-0.02-0.05-0.04-0.03-0.06
			c0.14-0.27,0.35-0.66,0.64-1.15c0.28-0.49,0.58-0.8,0.88-0.93c0.47-0.2,0.99-0.13,1.56,0.19c0.68,0.4,1.01,1.01,0.96,1.59
			c0,0.03,0.01,0.04,0.04,0.02c0.25-0.08,0.66-0.06,1.23,0.06c0.82,0.16,1.43,0.28,1.83,0.35c0.04,0,0.06,0.02,0.04,0.05
			c0-0.01-0.03,0.05-0.1,0.15c-0.07,0.1-0.12,0.19-0.16,0.25c-0.07,0.12-0.18,0.37-0.2,0.4c0,0.01-0.02,0.01-0.06,0
			c-0.33-0.1-0.89-0.23-1.7-0.38c-0.42-0.08-0.71-0.11-0.86-0.06c-0.13,0.04-0.25,0.16-0.36,0.35L96.4,83.23z"
        />
        <path
          class="st0"
          d="M96.82,79.27c-0.16-0.07-0.52-0.22-1.09-0.46c-0.58-0.24-0.85-0.36-0.84-0.36c-0.04-0.01-0.04-0.03-0.03-0.05
			c0-0.01,0.03-0.06,0.08-0.16c0.06-0.11,0.09-0.18,0.12-0.23c0.02-0.04,0.05-0.11,0.08-0.22c0.03-0.11,0.06-0.17,0.06-0.17
			c0-0.01,0.03-0.01,0.06,0.01c-0.04-0.02,0.17,0.08,0.65,0.3c0.47,0.22,0.9,0.4,1.29,0.57c0.85,0.37,1.45,0.63,1.78,0.77
			c0.87,0.36,1.43,0.24,1.69-0.35c0.28-0.65-0.01-1.17-0.9-1.58c-0.28-0.13-0.85-0.38-1.73-0.76c-0.38-0.17-0.83-0.35-1.32-0.56
			c-0.49-0.2-0.71-0.3-0.65-0.27c-0.03-0.01-0.04-0.03-0.03-0.05c0.1-0.19,0.16-0.3,0.18-0.35c0.04-0.1,0.14-0.38,0.15-0.41
			c0-0.01,0.02-0.01,0.05,0c-0.02-0.01,0.24,0.12,0.82,0.38c0.56,0.26,0.92,0.41,1.09,0.49l1.64,0.72c0.8,0.35,1.34,0.79,1.59,1.31
			c0.21,0.47,0.21,0.96-0.02,1.5c-0.49,1.12-1.48,1.35-2.96,0.7L96.82,79.27z"
        />
        <path
          class="st0"
          d="M99.89,73.13c0.09-0.27,0.05-0.53-0.11-0.74c-0.13-0.18-0.32-0.3-0.59-0.39c-0.55-0.17-0.92,0.02-1.1,0.6
			c-0.09,0.28-0.15,0.48-0.17,0.59c-0.01,0.03,0,0.04,0.04,0.05c0.5,0.17,1.07,0.34,1.67,0.52c0.04,0.01,0.05,0.01,0.06-0.02
			C99.73,73.64,99.8,73.43,99.89,73.13 M100.59,74.02c-0.01,0.03,0.01,0.04,0.06,0.06c0.47,0.15,0.98,0.3,1.51,0.46
			c0.53,0.15,0.77,0.22,0.71,0.2l0.18,0.05c0.04,0.01,0.04,0.02,0.04,0.05c0-0.01-0.09,0.21-0.14,0.35c-0.04,0.12-0.09,0.38-0.1,0.4
			c0,0.01-0.02,0.01-0.05,0c0.01,0-0.33-0.1-1.04-0.33c-0.7-0.23-1.46-0.47-2.26-0.72c-0.53-0.16-1.02-0.32-1.46-0.44
			c-0.31-0.1-0.59-0.18-0.85-0.25c-0.23-0.06-0.33-0.09-0.32-0.09c-0.04-0.01-0.06-0.03-0.05-0.05c0.08-0.29,0.2-0.72,0.37-1.26
			c0.17-0.54,0.38-0.91,0.65-1.1c0.42-0.3,0.93-0.35,1.56-0.16c0.75,0.23,1.21,0.76,1.29,1.34c0,0.03,0.02,0.03,0.05,0.01
			c0.22-0.14,0.63-0.2,1.22-0.22c0.83-0.02,1.46-0.04,1.87-0.06c0.04-0.01,0.06,0.01,0.05,0.04c0-0.01-0.02,0.05-0.07,0.17
			c-0.05,0.11-0.08,0.21-0.1,0.28c-0.04,0.13-0.1,0.4-0.11,0.43c0,0.01-0.02,0.01-0.06,0.01c-0.34-0.02-0.92-0.02-1.74,0
			c-0.43,0.01-0.71,0.05-0.85,0.13c-0.11,0.07-0.21,0.21-0.27,0.42L100.59,74.02z"
        />
        <path
          class="st0"
          d="M101.86,68.25l-0.47-0.09c-0.11-0.01-0.16-0.02-0.17-0.02c-0.04-0.01-0.06-0.03-0.05-0.08
			c0,0.01,0.01-0.07,0.06-0.26c0.05-0.19,0.08-0.36,0.11-0.5c0.03-0.15,0.06-0.32,0.09-0.52c0.03-0.21,0.05-0.3,0.04-0.29
			c0.01-0.03,0.03-0.04,0.09-0.03c-0.02,0,0.12,0.02,0.42,0.09c0.3,0.07,0.54,0.11,0.73,0.15c0.25,0.05,0.58,0.11,0.97,0.18
			c0.39,0.07,0.57,0.1,0.56,0.1c0.06,0.01,0.11,0.03,0.12,0.05c0.4,0.6,0.54,1.24,0.42,1.88c-0.13,0.69-0.53,1.2-1.18,1.52
			c-0.68,0.33-1.52,0.4-2.54,0.21c-1.03-0.2-1.81-0.6-2.32-1.21c-0.46-0.55-0.62-1.14-0.5-1.76c0.11-0.59,0.41-1.1,0.91-1.51
			c0.03-0.02,0.07-0.03,0.1,0c0.21,0.16,0.46,0.33,0.79,0.52c0.01,0.01,0.02,0.02,0.02,0.03c0,0.01-0.02,0.02-0.03,0.03
			c-0.49,0.33-0.78,0.71-0.86,1.13s0.06,0.81,0.43,1.17c0.37,0.35,0.87,0.59,1.51,0.71c0.79,0.15,1.43,0.11,1.94-0.12
			c0.44-0.21,0.7-0.52,0.79-0.95c0.07-0.35,0.03-0.66-0.12-0.96c-0.01-0.01-0.02-0.02-0.04-0.03c-0.17-0.04-0.42-0.09-0.77-0.16
			c-0.13-0.03-0.32-0.06-0.55-0.1c-0.02,0-0.05,0-0.09,0.02c-0.04,0.16-0.07,0.28-0.08,0.36c-0.03,0.15-0.06,0.43-0.07,0.47
			c0,0.02-0.02,0.02-0.05,0.02C102.04,68.29,101.98,68.28,101.86,68.25"
        />
        <path
          class="st0"
          d="M104.66,65.22c-0.36-0.04-0.71-0.08-1.09-0.12c-0.55-0.06-1.1-0.12-1.67-0.18c-0.57-0.06-1.1-0.11-1.61-0.15
			c-0.36-0.04-0.69-0.07-1-0.09c-0.27-0.02-0.39-0.03-0.38-0.03c-0.05,0-0.07-0.03-0.07-0.05c0.03-0.18,0.05-0.3,0.06-0.37
			c0.01-0.06,0.01-0.14,0.01-0.25c0-0.12,0.01-0.18,0.01-0.2c0-0.04,0.02-0.05,0.05-0.05c-0.09-0.01,0.2,0.03,0.85,0.1
			s1.37,0.15,2.18,0.23c0.6,0.06,1.17,0.12,1.71,0.16c0.38,0.04,0.72,0.07,1.06,0.1c0.28,0.02,0.41,0.03,0.39,0.03
			c0.04,0,0.06,0.01,0.06,0.03c-0.04,0.25-0.06,0.39-0.07,0.44c-0.01,0.08-0.01,0.39-0.02,0.43c0,0.01-0.03,0.02-0.06,0.01
			C105.1,65.27,104.97,65.26,104.66,65.22"
        />
        <path
          class="st0"
          d="M104.48,60.03c-0.01-0.3,0-0.48,0-0.53c0-0.03,0.01-0.04,0.04-0.04l0.15,0c0.11,0.01,0.2,0.01,0.27,0.01
			c0.05,0,0.15,0,0.28,0l0.21,0c0.02,0,0.04,0.01,0.04,0.04c0-0.06-0.02,0.17-0.04,0.66c-0.02,0.49-0.04,0.9-0.05,1.21
			c0,0.1,0.01,0.29,0.01,0.54c0.01,0.25,0,0.38,0,0.4c0,0.01-0.03,0.02-0.06,0.02c0.02,0-0.11,0-0.42-0.02
			c-0.36-0.02-0.71-0.03-1.1-0.03c-0.55-0.02-1.11-0.04-1.68-0.05c-0.57-0.01-1.11-0.03-1.62-0.03c-0.36-0.01-0.7-0.02-1.01-0.01
			c-0.28,0-0.41,0-0.38,0c-0.04,0-0.06-0.02-0.06-0.05c0,0.01,0-0.09,0.02-0.31c0.02-0.21,0.02-0.39,0.02-0.53
			c0.01-0.39,0.02-0.74,0.01-1.06c0.01-0.22,0.01-0.43,0-0.6c-0.01-0.16-0.01-0.22-0.01-0.2c0-0.02,0.01-0.03,0.05-0.03l0.17,0
			c0.11,0.01,0.21,0.01,0.28,0.02c0.08,0,0.16,0,0.25,0l0.16,0c0.03,0,0.04,0.01,0.04,0.04c0,0.07-0.01,0.29-0.03,0.66
			c-0.02,0.36-0.02,0.57-0.03,0.63c-0.01,0.23-0.01,0.42,0,0.55c0,0.03,0.02,0.05,0.06,0.05c0.27,0.02,0.52,0.02,0.74,0.03
			c0.26,0.01,0.52,0.01,0.8,0.01c0.04,0,0.06-0.01,0.06-0.04c0.02-0.23,0.02-0.43,0.03-0.61c0.01-0.22,0.01-0.44,0-0.66
			c-0.01-0.22-0.02-0.34-0.02-0.35c0-0.03,0.01-0.04,0.05-0.04l0.18,0c0.11,0.01,0.2,0.01,0.26,0.02c0.06,0,0.15,0,0.24,0l0.16,0
			c0.04,0,0.06,0.01,0.06,0.04c-0.04,0.56-0.06,0.93-0.06,1.09c0,0.12,0.01,0.29,0.01,0.52c0,0.03,0.02,0.05,0.06,0.05
			c0.31,0.02,0.6,0.02,0.88,0.03c0.31,0.01,0.59,0.01,0.88,0.01c0.04,0,0.05-0.02,0.05-0.04c0.02-0.24,0.02-0.51,0.03-0.79
			C104.48,60.53,104.49,60.32,104.48,60.03"
        />
        <path
          class="st0"
          d="M16.25,40.58c-0.61-0.27-1.15-0.35-1.63-0.25c-0.42,0.09-0.73,0.38-0.95,0.88c-0.07,0.16-0.13,0.31-0.18,0.44
			c-0.01,0.03,0.01,0.05,0.04,0.07l0.84,0.37c0.47,0.22,0.84,0.38,1.13,0.5c0.26,0.11,0.61,0.27,1.08,0.46l0.81,0.36
			c0.04,0.02,0.07,0.01,0.09-0.01c0.11-0.22,0.22-0.45,0.32-0.68C18.15,41.9,17.64,41.19,16.25,40.58 M14.19,39.44
			c0.68-0.17,1.45-0.08,2.32,0.3c2,0.88,2.77,2.08,2.18,3.43c-0.03,0.06-0.1,0.2-0.21,0.42c-0.11,0.22-0.19,0.39-0.24,0.52
			c-0.04,0.09-0.13,0.36-0.19,0.49c-0.01,0.03-0.04,0.03-0.09,0.01c0.02,0.01-0.31-0.13-0.97-0.43c-0.67-0.31-1.39-0.63-2.16-0.97
			c-0.7-0.31-1.31-0.57-1.83-0.78c-0.51-0.21-0.75-0.32-0.69-0.29c-0.03-0.01-0.05-0.04-0.04-0.07c0.29-0.72,0.46-1.15,0.52-1.29
			C13.11,40.05,13.58,39.61,14.19,39.44"
        />
        <path
          class="st0"
          d="M20.29,37.88c0.14-0.27,0.23-0.42,0.25-0.46c0.01-0.02,0.03-0.03,0.05-0.01l0.13,0.07
			c0.09,0.06,0.17,0.11,0.23,0.14c0.04,0.02,0.13,0.07,0.25,0.13l0.18,0.1c0.02,0.01,0.03,0.03,0.01,0.05
			c0.03-0.05-0.09,0.14-0.35,0.56c-0.25,0.42-0.46,0.77-0.62,1.04c-0.05,0.09-0.13,0.26-0.24,0.48c-0.11,0.22-0.18,0.34-0.18,0.35
			c-0.01,0.01-0.03,0-0.06-0.02c0.02,0.01-0.1-0.05-0.36-0.21c-0.31-0.18-0.62-0.36-0.95-0.55c-0.48-0.28-0.96-0.56-1.46-0.84
			c-0.49-0.28-0.97-0.55-1.41-0.79c-0.31-0.18-0.61-0.35-0.88-0.49c-0.25-0.13-0.36-0.19-0.34-0.18c-0.03-0.02-0.04-0.05-0.03-0.07
			c0,0.01,0.05-0.08,0.16-0.26c0.12-0.18,0.2-0.33,0.27-0.45c0.19-0.34,0.37-0.64,0.51-0.92c0.11-0.19,0.21-0.37,0.29-0.53
			c0.07-0.14,0.1-0.2,0.09-0.18c0.01-0.02,0.03-0.02,0.06,0l0.15,0.09c0.09,0.06,0.18,0.11,0.24,0.15c0.07,0.04,0.14,0.08,0.22,0.11
			l0.14,0.08c0.02,0.01,0.03,0.03,0.01,0.05c-0.03,0.06-0.14,0.25-0.33,0.57c-0.19,0.31-0.29,0.49-0.32,0.54
			c-0.12,0.2-0.21,0.36-0.26,0.48c-0.01,0.02,0,0.05,0.03,0.07c0.23,0.14,0.45,0.26,0.64,0.37c0.23,0.13,0.45,0.26,0.7,0.39
			c0.03,0.02,0.06,0.02,0.07,0c0.12-0.2,0.22-0.37,0.31-0.52c0.11-0.19,0.22-0.39,0.31-0.58c0.1-0.2,0.15-0.31,0.15-0.32
			c0.01-0.02,0.03-0.03,0.06-0.01l0.16,0.09c0.09,0.06,0.17,0.11,0.22,0.14c0.05,0.03,0.13,0.07,0.21,0.11l0.14,0.08
			c0.03,0.02,0.05,0.04,0.04,0.06c-0.3,0.48-0.49,0.8-0.57,0.93c-0.06,0.11-0.13,0.26-0.23,0.46c-0.01,0.02,0,0.05,0.03,0.07
			c0.26,0.16,0.52,0.31,0.76,0.44c0.27,0.15,0.51,0.29,0.77,0.42c0.03,0.02,0.05,0.01,0.07-0.02c0.13-0.2,0.26-0.44,0.4-0.69
			C20.06,38.32,20.16,38.15,20.29,37.88"
        />
        <path
          class="st0"
          d="M18.53,34.58c-0.14-0.1-0.47-0.32-0.97-0.67c-0.52-0.36-0.76-0.53-0.75-0.52c-0.03-0.02-0.04-0.04-0.02-0.06
			c0.01-0.01,0.04-0.05,0.11-0.14c0.08-0.09,0.13-0.16,0.16-0.2c0.03-0.04,0.07-0.1,0.12-0.2c0.05-0.1,0.09-0.15,0.1-0.16
			c0.01-0.01,0.03,0,0.06,0.02c-0.04-0.03,0.15,0.11,0.57,0.43c0.42,0.31,0.8,0.58,1.14,0.82c0.75,0.54,1.29,0.92,1.58,1.12
			c0.77,0.53,1.35,0.53,1.73,0.01c0.41-0.57,0.23-1.15-0.55-1.73c-0.25-0.19-0.76-0.55-1.53-1.11c-0.34-0.24-0.74-0.52-1.18-0.82
			s-0.63-0.44-0.58-0.4c-0.03-0.02-0.04-0.04-0.02-0.06c0.13-0.17,0.22-0.26,0.25-0.3c0.07-0.09,0.22-0.35,0.23-0.37
			c0.01-0.01,0.02-0.01,0.05,0.01c-0.02-0.01,0.21,0.17,0.72,0.54c0.5,0.37,0.82,0.6,0.97,0.7l1.45,1.04
			c0.71,0.51,1.15,1.05,1.29,1.61c0.11,0.5,0.01,0.98-0.33,1.46c-0.71,0.99-1.73,1.02-3.04,0.08L18.53,34.58z"
        />
        <path
          class="st0"
          d="M20.32,29.86l-0.42-0.37c-0.1-0.08-0.15-0.12-0.15-0.12c-0.03-0.03-0.03-0.06-0.02-0.08
			c-0.04,0.05,0.07-0.08,0.36-0.39c0.29-0.3,0.61-0.66,0.96-1.07c0.26-0.3,0.5-0.57,0.72-0.83l0.42-0.48
			c0.11-0.14,0.16-0.2,0.14-0.18c0.02-0.02,0.04-0.03,0.06-0.01l0.13,0.12c0.09,0.09,0.16,0.15,0.21,0.2
			c0.06,0.05,0.13,0.11,0.23,0.19l0.14,0.12c0.03,0.02,0.03,0.04,0.02,0.05c0.01-0.01-0.09,0.1-0.29,0.31
			c-0.2,0.21-0.33,0.34-0.37,0.38c-0.14,0.16-0.26,0.3-0.37,0.43c-0.01,0.01-0.01,0.03,0.02,0.05l0.82,0.73
			c0.32,0.29,0.74,0.67,1.27,1.13c0.38,0.32,0.8,0.68,1.26,1.08c0.4,0.35,0.6,0.52,0.58,0.5c0.03,0.02,0.04,0.05,0.03,0.06
			c-0.17,0.17-0.28,0.27-0.31,0.31c-0.03,0.03-0.09,0.1-0.16,0.19c-0.06,0.09-0.11,0.14-0.11,0.15c-0.01,0.01-0.01,0-0.04-0.03
			c0.02,0.02-0.18-0.16-0.58-0.53c-0.45-0.41-0.86-0.78-1.22-1.11c-0.51-0.47-0.92-0.83-1.2-1.08l-0.87-0.76
			c-0.03-0.02-0.05-0.03-0.07-0.02c-0.11,0.1-0.25,0.26-0.43,0.46c-0.04,0.05-0.14,0.18-0.31,0.39c-0.16,0.2-0.25,0.31-0.27,0.32
			C20.49,30,20.47,30,20.44,29.98L20.32,29.86z"
        />
        <path
          class="st0"
          d="M26.53,26.38c0.34-0.06,0.63-0.09,0.89-0.04c0.37,0.06,0.7,0.24,0.98,0.54c0.45,0.48,0.66,0.96,0.65,1.46
			c-0.01,0.43-0.2,0.81-0.54,1.14c-0.45,0.43-0.93,0.67-1.46,0.75c-0.03,0.01-0.05-0.02-0.09-0.07c-0.04-0.11-0.18-0.38-0.41-0.82
			c-0.03-0.06-0.01-0.08,0.05-0.08c0.53-0.04,0.95-0.2,1.25-0.48c0.2-0.18,0.28-0.39,0.27-0.61c0-0.2-0.09-0.39-0.26-0.57
			c-0.27-0.29-0.65-0.37-1.15-0.26l-0.54,0.12c-0.41,0.1-0.74,0.12-0.98,0.09c-0.43-0.05-0.81-0.25-1.15-0.61
			c-0.31-0.33-0.49-0.71-0.52-1.14c-0.04-0.49,0.14-0.91,0.53-1.28c0.37-0.35,0.79-0.54,1.28-0.58c0.02,0,0.05,0.02,0.06,0.06
			c0.06,0.16,0.2,0.44,0.39,0.82c0.02,0.03,0.01,0.06-0.04,0.06c-0.48,0.01-0.85,0.14-1.12,0.39c-0.32,0.3-0.28,0.72-0.01,1
			c0.27,0.29,0.68,0.37,1.24,0.25L26.53,26.38z"
        />
        <path
          class="st0"
          d="M32.73,25.28c-0.17,0.57-0.47,1.02-0.91,1.37c-0.5,0.39-1.09,0.49-1.78,0.29c-0.71-0.21-1.39-0.71-2.01-1.51
			c-0.66-0.85-0.99-1.66-0.98-2.44c0-0.7,0.27-1.28,0.8-1.69c0.42-0.33,0.89-0.49,1.4-0.47c0.04,0,0.07,0.02,0.08,0.06
			c0.1,0.38,0.19,0.68,0.28,0.9c0.02,0.04,0,0.06-0.04,0.06c-0.51-0.04-0.91,0.05-1.2,0.28c-0.31,0.24-0.46,0.57-0.45,1.02
			c0.01,0.51,0.25,1.06,0.71,1.64c0.46,0.59,0.94,0.97,1.45,1.14c0.48,0.17,0.89,0.12,1.22-0.14c0.2-0.15,0.39-0.49,0.57-1.01
			c0.01-0.04,0.04-0.04,0.07-0.02c0.2,0.14,0.46,0.3,0.76,0.47C32.72,25.23,32.74,25.25,32.73,25.28"
        />
        <path
          class="st0"
          d="M34.18,20.25c-0.29-0.47-0.59-0.96-0.9-1.44c-0.31-0.49-0.45-0.71-0.41-0.65c-0.02-0.03-0.02-0.05,0-0.06
			c0.01-0.01,0.05-0.03,0.14-0.07c0.09-0.04,0.17-0.09,0.2-0.11c0.04-0.02,0.11-0.07,0.21-0.14c0.1-0.07,0.15-0.11,0.17-0.12
			c0.02-0.01,0.03,0,0.04,0.02c-0.01-0.02,0.05,0.08,0.19,0.32c0.15,0.27,0.33,0.55,0.52,0.87c0.27,0.46,0.56,0.93,0.87,1.43
			c0.32,0.51,0.62,1,0.9,1.44c0.19,0.32,0.38,0.62,0.56,0.9c0.16,0.24,0.24,0.37,0.25,0.38c0.01,0.01,0,0.02-0.02,0.02
			c-0.2,0.11-0.33,0.17-0.37,0.2c-0.04,0.02-0.11,0.07-0.21,0.14c-0.1,0.07-0.15,0.11-0.16,0.11c-0.01,0.01-0.01-0.01-0.03-0.04
			c0.03,0.05-0.12-0.19-0.43-0.72c-0.32-0.53-0.65-1.07-0.99-1.62c-0.01-0.02-0.03-0.03-0.03-0.02c-0.35,0.2-0.66,0.38-0.94,0.55
			c-0.24,0.15-0.54,0.35-0.91,0.59c-0.01,0.01-0.02,0.03,0,0.05c0.33,0.54,0.67,1.08,1.02,1.63c0.35,0.55,0.49,0.78,0.44,0.7
			c0.01,0.02,0.01,0.04-0.01,0.05c-0.21,0.12-0.34,0.18-0.38,0.2c-0.04,0.02-0.11,0.07-0.2,0.14c-0.09,0.07-0.14,0.1-0.14,0.1
			c-0.01,0.01-0.03,0-0.05-0.03c0.01,0.01-0.07-0.11-0.22-0.37c-0.18-0.31-0.37-0.62-0.57-0.95c-0.28-0.47-0.57-0.94-0.87-1.42
			c-0.41-0.66-0.78-1.26-1.13-1.8c-0.35-0.55-0.51-0.8-0.48-0.76c-0.02-0.03-0.02-0.05,0-0.06c0.02-0.01,0.26-0.13,0.34-0.18
			c0.04-0.02,0.1-0.06,0.2-0.14c0.1-0.07,0.15-0.11,0.17-0.12c0.02-0.01,0.04-0.01,0.06,0.02c0.01,0.01,0.15,0.25,0.44,0.73
			c0.28,0.48,0.57,0.94,0.84,1.38c0.01,0.02,0.03,0.03,0.04,0.02c0.38-0.22,0.68-0.39,0.92-0.54c0.22-0.14,0.52-0.34,0.92-0.59
			C34.2,20.29,34.2,20.27,34.18,20.25"
        />
        <path
          class="st0"
          d="M39.71,20.62c0.27-0.14,0.43-0.22,0.47-0.24c0.02-0.01,0.04,0,0.05,0.02l0.06,0.13
			c0.04,0.1,0.08,0.18,0.11,0.25c0.02,0.04,0.06,0.13,0.13,0.25l0.09,0.19c0.01,0.02,0.01,0.04-0.02,0.05
			c0.05-0.02-0.16,0.06-0.6,0.26c-0.45,0.2-0.82,0.36-1.11,0.5c-0.09,0.04-0.25,0.13-0.47,0.25c-0.22,0.12-0.34,0.17-0.35,0.18
			c-0.01,0-0.03-0.01-0.04-0.05c0.01,0.02-0.05-0.1-0.17-0.38c-0.15-0.33-0.3-0.65-0.46-1c-0.23-0.51-0.47-1.01-0.71-1.52
			c-0.24-0.51-0.48-1-0.7-1.46c-0.15-0.32-0.3-0.63-0.44-0.91c-0.13-0.25-0.18-0.36-0.17-0.34c-0.02-0.03-0.01-0.06,0.01-0.08
			c-0.01,0,0.08-0.04,0.28-0.12c0.2-0.08,0.36-0.15,0.48-0.21c0.35-0.17,0.67-0.32,0.95-0.46c0.2-0.09,0.38-0.18,0.54-0.27
			c0.14-0.08,0.2-0.11,0.18-0.1c0.02-0.01,0.03,0,0.05,0.03l0.07,0.16c0.04,0.1,0.08,0.19,0.11,0.26c0.03,0.07,0.07,0.14,0.11,0.22
			l0.07,0.14c0.01,0.02,0.01,0.04-0.02,0.05c-0.06,0.03-0.26,0.12-0.6,0.27c-0.33,0.14-0.52,0.23-0.57,0.26
			c-0.21,0.1-0.38,0.18-0.49,0.24c-0.03,0.01-0.03,0.04-0.02,0.08c0.11,0.25,0.21,0.47,0.31,0.67c0.11,0.23,0.22,0.47,0.35,0.72
			c0.02,0.03,0.03,0.05,0.06,0.04c0.21-0.09,0.4-0.18,0.56-0.25c0.2-0.1,0.4-0.19,0.59-0.29c0.19-0.11,0.3-0.17,0.31-0.17
			c0.03-0.01,0.04-0.01,0.06,0.03l0.08,0.17c0.04,0.1,0.08,0.18,0.1,0.24c0.03,0.06,0.06,0.13,0.11,0.21l0.07,0.14
			c0.02,0.03,0.02,0.06-0.01,0.07c-0.52,0.22-0.86,0.37-1,0.44c-0.11,0.05-0.25,0.13-0.46,0.24c-0.02,0.01-0.03,0.04-0.01,0.08
			c0.12,0.28,0.25,0.55,0.37,0.8c0.13,0.28,0.25,0.54,0.38,0.79c0.02,0.03,0.04,0.04,0.06,0.02c0.22-0.09,0.46-0.21,0.72-0.33
			C39.26,20.84,39.45,20.75,39.71,20.62"
        />
        <path
          class="st0"
          d="M45.83,16.51l-0.12-0.46c-0.04-0.1-0.05-0.15-0.05-0.16c-0.01-0.04,0-0.06,0.05-0.08
			c-0.01,0,0.07-0.02,0.26-0.06c0.19-0.04,0.36-0.09,0.49-0.12c0.14-0.04,0.31-0.08,0.51-0.15c0.2-0.07,0.29-0.09,0.28-0.09
			c0.03-0.01,0.05,0.01,0.07,0.07c-0.01-0.02,0.03,0.12,0.1,0.42c0.07,0.3,0.14,0.54,0.18,0.72c0.07,0.25,0.15,0.57,0.27,0.95
			c0.11,0.38,0.16,0.56,0.16,0.55c0.02,0.06,0.02,0.11,0.01,0.13c-0.37,0.62-0.87,1.03-1.51,1.2c-0.68,0.18-1.31,0.05-1.88-0.4
			c-0.6-0.47-1.03-1.19-1.3-2.19c-0.27-1.01-0.25-1.89,0.07-2.61c0.29-0.65,0.75-1.06,1.37-1.23c0.58-0.16,1.17-0.11,1.76,0.15
			c0.03,0.02,0.05,0.05,0.04,0.09c-0.05,0.26-0.1,0.56-0.12,0.94c-0.01,0.01-0.01,0.03-0.02,0.03c-0.01,0-0.03,0-0.04-0.01
			c-0.51-0.3-0.98-0.39-1.4-0.28c-0.42,0.11-0.71,0.41-0.86,0.9c-0.15,0.49-0.15,1.04,0.02,1.67c0.21,0.77,0.52,1.34,0.96,1.7
			c0.38,0.31,0.78,0.4,1.2,0.29c0.34-0.09,0.61-0.27,0.82-0.53c0.01-0.01,0.01-0.03,0-0.05c-0.04-0.17-0.1-0.42-0.2-0.76
			c-0.04-0.13-0.08-0.31-0.15-0.53c-0.01-0.02-0.02-0.05-0.06-0.07c-0.16,0.03-0.28,0.06-0.36,0.08c-0.15,0.04-0.41,0.14-0.45,0.15
			c-0.02,0-0.03-0.01-0.04-0.04C45.87,16.69,45.85,16.63,45.83,16.51"
        />
        <path
          class="st0"
          d="M51.37,17.28c0.3-0.06,0.47-0.09,0.52-0.1c0.03,0,0.04,0.01,0.04,0.03l0.02,0.15
			c0.01,0.11,0.02,0.2,0.03,0.27c0.01,0.05,0.02,0.15,0.05,0.28l0.03,0.21c0,0.02,0,0.04-0.03,0.04c0.06-0.01-0.17,0.01-0.65,0.08
			c-0.49,0.07-0.89,0.12-1.2,0.17c-0.1,0.02-0.28,0.06-0.53,0.11s-0.37,0.07-0.39,0.07c-0.01,0-0.02-0.02-0.03-0.06
			c0,0.02-0.02-0.11-0.06-0.42c-0.05-0.36-0.1-0.71-0.16-1.09c-0.08-0.55-0.17-1.1-0.26-1.66s-0.18-1.1-0.27-1.59
			c-0.06-0.35-0.11-0.69-0.17-1c-0.06-0.28-0.07-0.4-0.07-0.38c-0.01-0.04,0.01-0.06,0.04-0.07c-0.01,0,0.09-0.01,0.3-0.04
			c0.21-0.02,0.39-0.05,0.52-0.07c0.38-0.06,0.73-0.12,1.04-0.18c0.22-0.04,0.42-0.07,0.59-0.11c0.15-0.04,0.22-0.05,0.2-0.04
			c0.02,0,0.03,0.01,0.04,0.04l0.03,0.17c0.01,0.11,0.02,0.21,0.04,0.28c0.01,0.07,0.03,0.16,0.05,0.24l0.02,0.16
			c0,0.02,0,0.04-0.03,0.04c-0.06,0.01-0.28,0.05-0.65,0.09c-0.36,0.04-0.57,0.08-0.62,0.09c-0.23,0.04-0.41,0.07-0.54,0.1
			c-0.03,0-0.04,0.03-0.04,0.07c0.03,0.27,0.07,0.51,0.11,0.73c0.04,0.26,0.08,0.51,0.14,0.79c0.01,0.04,0.02,0.06,0.05,0.06
			c0.23-0.02,0.43-0.06,0.6-0.08c0.22-0.04,0.44-0.07,0.65-0.12c0.22-0.05,0.33-0.08,0.34-0.08c0.03,0,0.04,0.01,0.04,0.04
			l0.03,0.18c0.01,0.11,0.02,0.2,0.03,0.26c0.01,0.06,0.02,0.15,0.05,0.23l0.02,0.16c0.01,0.04,0,0.06-0.03,0.07
			c-0.56,0.07-0.93,0.11-1.08,0.14c-0.12,0.02-0.28,0.06-0.51,0.11c-0.03,0.01-0.04,0.03-0.04,0.07c0.04,0.31,0.09,0.6,0.13,0.87
			c0.05,0.3,0.09,0.58,0.15,0.86c0.01,0.04,0.03,0.05,0.05,0.04c0.24-0.03,0.51-0.07,0.79-0.11C50.87,17.37,51.08,17.34,51.37,17.28
			"
        />
        <path
          class="st0"
          d="M54.73,14.49c0.3,0.17,0.54,0.33,0.71,0.53c0.25,0.28,0.39,0.63,0.42,1.04c0.04,0.65-0.1,1.16-0.42,1.54
			c-0.28,0.33-0.67,0.5-1.14,0.54c-0.62,0.04-1.14-0.07-1.6-0.35c-0.03-0.01-0.03-0.05-0.03-0.11c0.04-0.11,0.1-0.4,0.21-0.89
			c0.01-0.06,0.04-0.06,0.09-0.03c0.44,0.3,0.86,0.45,1.27,0.42c0.27-0.02,0.46-0.12,0.6-0.3c0.13-0.16,0.18-0.36,0.16-0.61
			c-0.03-0.39-0.27-0.7-0.72-0.93l-0.5-0.25c-0.38-0.18-0.65-0.38-0.82-0.55c-0.3-0.31-0.47-0.71-0.5-1.2
			c-0.03-0.45,0.07-0.86,0.32-1.21c0.28-0.4,0.68-0.62,1.22-0.65c0.51-0.04,0.95,0.08,1.35,0.36c0.02,0.01,0.02,0.05,0.01,0.09
			c-0.05,0.17-0.12,0.47-0.22,0.88c-0.01,0.04-0.03,0.05-0.07,0.02c-0.37-0.29-0.74-0.43-1.11-0.4c-0.43,0.03-0.67,0.38-0.64,0.76
			c0.03,0.39,0.29,0.72,0.8,0.98L54.73,14.49z"
        />
        <path
          class="st0"
          d="M59.13,16.92c0.3-0.01,0.48,0,0.53,0c0.03,0,0.04,0.01,0.04,0.04l0,0.15c-0.01,0.11-0.01,0.2-0.01,0.27
			c0,0.05,0,0.15,0,0.28l0,0.21c0,0.02-0.01,0.04-0.04,0.04c0.05,0-0.17-0.02-0.66-0.04c-0.49-0.02-0.9-0.04-1.21-0.05
			c-0.1,0-0.29,0.01-0.54,0.01c-0.25,0.01-0.38,0.01-0.4,0.01c-0.01,0-0.02-0.02-0.02-0.06c0,0.02,0-0.11,0.02-0.42
			c0.02-0.36,0.02-0.72,0.03-1.1c0.02-0.55,0.03-1.11,0.04-1.68c0.01-0.57,0.02-1.11,0.02-1.62c0.01-0.36,0.01-0.7,0.01-1.01
			c0-0.28,0-0.41,0-0.38c0-0.04,0.02-0.06,0.05-0.06c-0.01,0,0.09,0,0.3,0.02c0.21,0.02,0.39,0.02,0.53,0.02
			c0.39,0.01,0.74,0.02,1.05,0.01c0.22,0,0.43,0.01,0.6,0c0.16-0.01,0.22-0.01,0.2-0.01c0.02,0,0.03,0.01,0.03,0.05l0,0.17
			c-0.01,0.11-0.01,0.21-0.01,0.28c0,0.07,0,0.16,0,0.25l0,0.16c0,0.02-0.01,0.04-0.04,0.04c-0.06,0-0.29-0.01-0.66-0.03
			c-0.36-0.02-0.57-0.02-0.63-0.02c-0.23,0-0.42-0.01-0.55,0c-0.03,0-0.05,0.02-0.05,0.06c-0.01,0.27-0.02,0.52-0.02,0.74
			c-0.01,0.26-0.01,0.52-0.01,0.8c0,0.04,0.01,0.06,0.04,0.06c0.23,0.02,0.44,0.02,0.61,0.02c0.22,0.01,0.44,0.01,0.66,0
			c0.22-0.01,0.34-0.02,0.35-0.02c0.03,0,0.04,0.01,0.04,0.05l0,0.18c-0.01,0.11-0.01,0.2-0.01,0.26c0,0.06,0,0.15,0,0.23l0,0.16
			c0,0.04-0.01,0.06-0.04,0.06c-0.56-0.04-0.93-0.06-1.09-0.06c-0.12,0-0.29,0.01-0.52,0.02c-0.03,0-0.05,0.02-0.05,0.06
			c-0.02,0.31-0.02,0.61-0.03,0.88c-0.01,0.31-0.01,0.59-0.01,0.88c0,0.04,0.02,0.05,0.05,0.05c0.24,0.02,0.51,0.02,0.79,0.03
			C58.63,16.92,58.83,16.93,59.13,16.92"
        />
        <path
          class="st0"
          d="M61.41,11.69c0.18,0.03,0.3,0.06,0.37,0.06c0.05,0.01,0.14,0.01,0.26,0.01c0.12,0,0.19,0.01,0.19,0.01
			c0.04,0,0.05,0.03,0.05,0.07c0.01-0.04-0.01,0.08-0.05,0.35c-0.04,0.31-0.08,0.64-0.12,1c-0.07,0.52-0.12,1.07-0.19,1.66
			c-0.04,0.37-0.08,0.8-0.13,1.29l-0.09,0.82c-0.01,0.06,0.01,0.09,0.04,0.09c0.29,0.04,0.56,0.07,0.8,0.1
			c0.09,0.01,0.29,0.03,0.61,0.05c0.31,0.02,0.47,0.02,0.46,0.02c0.03,0,0.04,0.02,0.04,0.05l-0.02,0.21
			c-0.02,0.13-0.03,0.24-0.04,0.3c-0.01,0.05-0.01,0.14-0.02,0.26l-0.02,0.18c0,0.04-0.01,0.05-0.04,0.04c0.02,0-0.18-0.03-0.6-0.09
			c-0.41-0.06-0.68-0.08-0.82-0.1c-0.29-0.03-0.59-0.05-0.91-0.07c-0.32-0.02-0.46-0.02-0.43-0.02c-0.05-0.01-0.06-0.03-0.06-0.07
			c0,0.02,0.01-0.11,0.05-0.42c0.05-0.34,0.08-0.71,0.13-1.09c0.07-0.55,0.13-1.1,0.19-1.67c0.06-0.56,0.12-1.1,0.16-1.61
			c0.04-0.37,0.08-0.71,0.1-1.02c0.02-0.27,0.03-0.39,0.03-0.37C61.36,11.71,61.39,11.68,61.41,11.69"
        />
        <path
          class="st0"
          d="M65.35,12.18c0.18,0.05,0.3,0.08,0.36,0.09c0.05,0.01,0.14,0.03,0.26,0.04c0.12,0.01,0.18,0.02,0.19,0.02
			c0.04,0.01,0.05,0.04,0.04,0.07c0.01-0.04-0.02,0.09-0.08,0.35c-0.07,0.3-0.13,0.63-0.21,0.99c-0.11,0.51-0.21,1.05-0.32,1.64
			c-0.07,0.36-0.15,0.79-0.23,1.27l-0.15,0.81c-0.01,0.06,0,0.09,0.03,0.09c0.29,0.07,0.55,0.12,0.79,0.16
			c0.09,0.02,0.29,0.06,0.6,0.1c0.31,0.05,0.47,0.06,0.46,0.06c0.03,0.01,0.04,0.02,0.04,0.06l-0.04,0.21
			c-0.03,0.13-0.05,0.24-0.07,0.3c-0.01,0.05-0.03,0.13-0.04,0.26l-0.03,0.18c-0.01,0.04-0.02,0.05-0.05,0.04
			c0.02,0-0.18-0.05-0.59-0.14c-0.41-0.09-0.67-0.14-0.81-0.17c-0.28-0.05-0.58-0.1-0.9-0.15c-0.32-0.05-0.46-0.06-0.42-0.05
			c-0.05-0.01-0.06-0.04-0.05-0.07c-0.01,0.02,0.02-0.11,0.09-0.41c0.07-0.34,0.14-0.7,0.21-1.08c0.11-0.54,0.22-1.09,0.32-1.65
			c0.11-0.56,0.21-1.09,0.29-1.59c0.07-0.36,0.13-0.7,0.18-1.01c0.04-0.27,0.07-0.39,0.06-0.36C65.3,12.2,65.32,12.18,65.35,12.18"
        />
        <path
          class="st0"
          d="M70.24,16.29c0.23,0.26,0.4,0.5,0.49,0.74c0.14,0.35,0.15,0.72,0.04,1.11c-0.18,0.63-0.48,1.06-0.91,1.31
			c-0.38,0.21-0.8,0.25-1.26,0.12c-0.6-0.17-1.06-0.45-1.39-0.86c-0.02-0.02-0.01-0.05,0.01-0.11c0.07-0.1,0.23-0.35,0.49-0.77
			c0.04-0.05,0.06-0.05,0.1,0c0.31,0.43,0.66,0.71,1.06,0.82c0.26,0.07,0.48,0.04,0.67-0.08c0.17-0.1,0.29-0.28,0.36-0.51
			c0.11-0.38-0.02-0.75-0.37-1.12l-0.39-0.4c-0.29-0.3-0.48-0.57-0.58-0.79c-0.18-0.4-0.2-0.83-0.07-1.3
			c0.12-0.44,0.36-0.79,0.7-1.03c0.4-0.29,0.85-0.35,1.37-0.2c0.49,0.14,0.87,0.4,1.15,0.8c0.01,0.02,0,0.05-0.02,0.08
			c-0.11,0.14-0.28,0.4-0.5,0.76c-0.02,0.03-0.05,0.04-0.07-0.01c-0.25-0.4-0.56-0.66-0.91-0.76c-0.42-0.12-0.76,0.13-0.86,0.5
			c-0.11,0.38,0.03,0.78,0.42,1.2L70.24,16.29z"
        />
        <path
          class="st0"
          d="M74.37,21.04c-0.57,0.15-1.12,0.12-1.64-0.08c-0.6-0.23-0.99-0.68-1.16-1.38c-0.19-0.72-0.1-1.56,0.26-2.5
			c0.39-1,0.92-1.71,1.59-2.1c0.61-0.36,1.23-0.42,1.86-0.18c0.5,0.19,0.88,0.51,1.13,0.96c0.03,0.04,0.02,0.07-0.01,0.1
			c-0.27,0.28-0.49,0.51-0.63,0.7c-0.02,0.03-0.05,0.03-0.07,0c-0.23-0.46-0.51-0.75-0.86-0.89c-0.36-0.14-0.73-0.1-1.11,0.14
			c-0.43,0.27-0.78,0.76-1.04,1.45c-0.27,0.7-0.35,1.31-0.23,1.83c0.1,0.5,0.36,0.83,0.74,0.98c0.23,0.09,0.62,0.08,1.15-0.03
			c0.04-0.01,0.06,0.01,0.05,0.05c-0.03,0.24-0.02,0.55-0.01,0.9C74.41,21.02,74.4,21.04,74.37,21.04"
        />
        <path
          class="st0"
          d="M79.43,19.72c0.26-0.49,0.52-1,0.78-1.51c0.26-0.52,0.38-0.75,0.35-0.68c0.02-0.03,0.03-0.04,0.05-0.03
			c0.01,0.01,0.05,0.03,0.13,0.08c0.09,0.06,0.16,0.1,0.2,0.12c0.04,0.02,0.11,0.06,0.23,0.1c0.11,0.05,0.17,0.08,0.19,0.08
			c0.02,0.01,0.02,0.02,0.01,0.05c0.01-0.02-0.04,0.09-0.18,0.32c-0.15,0.27-0.3,0.57-0.48,0.89c-0.25,0.47-0.51,0.96-0.78,1.48
			c-0.28,0.54-0.54,1.04-0.78,1.51c-0.17,0.33-0.34,0.64-0.48,0.94c-0.12,0.26-0.2,0.4-0.2,0.41c-0.01,0.01-0.01,0.01-0.03,0
			c-0.2-0.12-0.32-0.19-0.36-0.21c-0.04-0.02-0.12-0.06-0.23-0.11c-0.11-0.04-0.17-0.07-0.18-0.08c-0.01,0,0-0.01,0.01-0.05
			c-0.03,0.06,0.1-0.2,0.4-0.74c0.29-0.54,0.58-1.1,0.89-1.68c0.01-0.02,0.01-0.04,0-0.04c-0.35-0.19-0.66-0.37-0.95-0.52
			c-0.26-0.13-0.58-0.29-0.97-0.48c-0.02-0.01-0.03,0-0.04,0.02c-0.29,0.56-0.59,1.13-0.88,1.71c-0.29,0.58-0.42,0.83-0.37,0.74
			c-0.01,0.02-0.03,0.03-0.04,0.02c-0.21-0.12-0.33-0.2-0.37-0.22c-0.04-0.02-0.12-0.06-0.22-0.1c-0.1-0.04-0.15-0.07-0.16-0.07
			c-0.02-0.01-0.02-0.02,0-0.06c0,0.01,0.06-0.11,0.21-0.38c0.17-0.31,0.34-0.64,0.52-0.98c0.26-0.48,0.52-0.97,0.78-1.47
			c0.36-0.69,0.68-1.32,0.96-1.89c0.29-0.58,0.43-0.85,0.41-0.8c0.02-0.03,0.03-0.04,0.06-0.03c0.03,0.01,0.25,0.16,0.33,0.2
			c0.04,0.02,0.11,0.06,0.22,0.1c0.11,0.04,0.17,0.07,0.19,0.08c0.02,0.01,0.03,0.03,0.01,0.06c-0.01,0.01-0.14,0.26-0.4,0.75
			c-0.26,0.49-0.52,0.97-0.76,1.43c-0.01,0.02-0.01,0.04,0.01,0.05c0.38,0.21,0.69,0.39,0.93,0.51c0.23,0.12,0.56,0.28,0.98,0.48
			C79.41,19.75,79.42,19.74,79.43,19.72"
        />
        <path
          class="st0"
          d="M83.59,20.97c0.1-0.28,0.15-0.4,0.16-0.4s-0.08,0.09-0.28,0.26l-1.8,1.58c-0.02,0.01-0.02,0.03-0.01,0.04
			c0.12,0.09,0.28,0.21,0.5,0.35c0.3,0.2,0.5,0.34,0.62,0.4c0.02,0.01,0.03,0.01,0.04-0.03L83.59,20.97z M82.48,24.18
			c0.01-0.03,0-0.04-0.01-0.05c-0.12-0.09-0.38-0.27-0.78-0.54c-0.38-0.26-0.63-0.42-0.75-0.49c-0.01-0.01-0.02,0-0.05,0.01
			c-0.43,0.38-0.85,0.76-1.28,1.13c-0.01-0.01-0.05-0.04-0.13-0.1c-0.08-0.07-0.15-0.11-0.18-0.14c-0.04-0.03-0.11-0.08-0.23-0.14
			c-0.11-0.06-0.17-0.1-0.18-0.1c-0.01,0,0-0.02,0.03-0.04c0.81-0.66,1.71-1.42,2.73-2.28c0.73-0.64,1.48-1.28,2.22-1.92
			c0.01-0.01,0.04-0.01,0.05,0c0.01,0.01,0.06,0.04,0.16,0.12c0.1,0.08,0.18,0.13,0.22,0.16c0.04,0.03,0.12,0.08,0.23,0.14
			c0.12,0.07,0.18,0.1,0.18,0.11c0.02,0.01,0.03,0.04,0.02,0.07c-0.23,0.66-0.55,1.58-0.93,2.73c-0.29,0.88-0.65,2.02-1.08,3.42
			c-0.01,0.04-0.03,0.04-0.05,0.03c0.01,0.01-0.05-0.03-0.15-0.12s-0.18-0.14-0.24-0.18c-0.04-0.03-0.11-0.07-0.21-0.12
			s-0.15-0.08-0.15-0.09c-0.01-0.01-0.02-0.03-0.01-0.05C82.04,25.45,82.23,24.92,82.48,24.18"
        />
        <path
          class="st0"
          d="M87.18,23.05c-0.02-0.02-0.04,0-0.07,0.02c-0.26,0.3-0.48,0.56-0.59,0.68c-0.06,0.07-0.18,0.22-0.38,0.48
			c-0.02,0.03-0.03,0.05-0.01,0.07c0.15,0.14,0.31,0.27,0.49,0.41c0.18,0.15,0.35,0.29,0.52,0.41c0.17,0.13,0.24,0.18,0.21,0.16
			c0.02,0.02,0.02,0.03,0.01,0.05l-0.11,0.13c-0.09,0.09-0.14,0.16-0.17,0.19c-0.04,0.05-0.09,0.11-0.16,0.22
			c-0.16,0.19-0.13,0.18-0.17,0.15c0.02,0.02-0.08-0.07-0.29-0.25c-0.2-0.18-0.36-0.33-0.49-0.44c-0.14-0.11-0.27-0.22-0.43-0.34
			c-0.02-0.02-0.24,0.22-0.65,0.72c-0.24,0.3-0.51,0.62-0.79,0.98c-0.27,0.35-0.39,0.5-0.35,0.45c-0.02,0.02-0.04,0.02-0.06,0
			c0.01,0.01-0.04-0.03-0.11-0.11c-0.07-0.07-0.14-0.13-0.18-0.16c-0.04-0.03-0.23-0.16-0.32-0.23c-0.02-0.02-0.02-0.03,0-0.06
			c-0.05,0.07,0.15-0.18,0.61-0.73s0.95-1.12,1.45-1.73c0.36-0.44,0.71-0.86,1.02-1.26c0.24-0.29,0.45-0.55,0.64-0.8
			c0.17-0.22,0.24-0.31,0.23-0.29c0.02-0.03,0.05-0.04,0.07-0.02c-0.01-0.01,0.06,0.05,0.22,0.2c0.16,0.15,0.31,0.27,0.41,0.36
			c0.3,0.25,0.57,0.47,0.82,0.66c0.18,0.15,0.33,0.27,0.47,0.37c0.12,0.08,0.17,0.13,0.16,0.11c0.01,0.01,0.01,0.03,0,0.05
			c-0.15,0.17-0.25,0.29-0.31,0.36c-0.09,0.11-0.25,0.34-0.24,0.33c-0.02,0.02-0.03,0.02-0.04,0.01c0.01,0.01-0.14-0.12-0.44-0.38
			c-0.3-0.26-0.47-0.4-0.52-0.44C87.47,23.27,87.33,23.16,87.18,23.05"
        />
        <path
          class="st0"
          d="M89.17,24.53l0.39-0.4c0.08-0.1,0.12-0.14,0.13-0.15c0.03-0.03,0.06-0.03,0.08-0.01
			c-0.05-0.04,0.08,0.08,0.37,0.38c0.29,0.3,0.63,0.64,1.01,1.01c0.28,0.28,0.54,0.53,0.79,0.76l0.46,0.45
			c0.13,0.12,0.19,0.17,0.17,0.15c0.02,0.02,0.02,0.04,0.01,0.06l-0.12,0.12c-0.09,0.08-0.16,0.15-0.21,0.21
			c-0.05,0.05-0.12,0.12-0.2,0.22l-0.13,0.13c-0.03,0.03-0.04,0.03-0.06,0.01c0.01,0.01-0.1-0.1-0.29-0.31
			c-0.2-0.21-0.32-0.35-0.37-0.39c-0.15-0.15-0.28-0.28-0.41-0.39c-0.01-0.01-0.03-0.01-0.06,0.02l-0.77,0.78
			c-0.31,0.3-0.71,0.71-1.19,1.21c-0.34,0.36-0.72,0.76-1.14,1.2c-0.36,0.39-0.55,0.57-0.53,0.55c-0.03,0.03-0.05,0.04-0.06,0.02
			c-0.16-0.18-0.26-0.29-0.29-0.32c-0.03-0.03-0.09-0.09-0.19-0.17c-0.09-0.07-0.13-0.11-0.14-0.12c-0.01-0.01,0-0.02,0.03-0.04
			c-0.02,0.02,0.16-0.17,0.56-0.56c0.43-0.43,0.82-0.82,1.17-1.17c0.49-0.49,0.87-0.88,1.14-1.15l0.81-0.83
			c0.03-0.03,0.04-0.05,0.02-0.06c-0.1-0.11-0.25-0.26-0.44-0.45c-0.05-0.05-0.17-0.15-0.37-0.33c-0.19-0.17-0.29-0.27-0.31-0.28
			c-0.01-0.01,0-0.02,0.02-0.05L89.17,24.53z"
        />
        <path
          class="st0"
          d="M95.43,31.67c-0.02-0.02-0.04-0.01-0.07,0.01c-0.32,0.23-0.61,0.42-0.74,0.52c-0.07,0.05-0.23,0.17-0.49,0.37
			c-0.03,0.02-0.04,0.04-0.03,0.07c0.11,0.17,0.24,0.34,0.37,0.52c0.14,0.19,0.27,0.36,0.4,0.53c0.14,0.16,0.19,0.24,0.17,0.21
			c0.02,0.02,0.01,0.04-0.01,0.05l-0.14,0.1c-0.1,0.07-0.18,0.12-0.21,0.15c-0.05,0.04-0.12,0.09-0.21,0.17
			c-0.2,0.15-0.17,0.14-0.2,0.1c0.02,0.02-0.06-0.08-0.21-0.31c-0.15-0.22-0.27-0.41-0.37-0.54c-0.1-0.14-0.21-0.28-0.33-0.43
			c-0.02-0.02-0.29,0.15-0.81,0.54c-0.31,0.23-0.65,0.47-1.01,0.75c-0.35,0.27-0.5,0.39-0.45,0.35c-0.02,0.01-0.04,0.01-0.06-0.02
			c0.01,0.01-0.03-0.04-0.08-0.13c-0.05-0.09-0.1-0.16-0.13-0.2c-0.03-0.04-0.18-0.21-0.25-0.3c-0.02-0.02-0.01-0.04,0.02-0.06
			c-0.07,0.05,0.19-0.14,0.77-0.55c0.58-0.42,1.2-0.85,1.83-1.32c0.46-0.34,0.9-0.66,1.3-0.96c0.3-0.22,0.58-0.42,0.82-0.61
			c0.21-0.17,0.31-0.24,0.29-0.23c0.03-0.02,0.05-0.03,0.07-0.01c-0.01-0.01,0.05,0.07,0.16,0.25c0.12,0.19,0.23,0.33,0.31,0.45
			c0.23,0.31,0.44,0.6,0.63,0.84c0.14,0.19,0.25,0.34,0.37,0.48c0.1,0.11,0.14,0.16,0.12,0.15c0.01,0.02,0.01,0.03-0.01,0.04
			c-0.18,0.12-0.31,0.22-0.38,0.27c-0.12,0.09-0.33,0.26-0.32,0.26c-0.02,0.01-0.03,0.01-0.05,0c0.01,0.01-0.11-0.15-0.34-0.48
			c-0.23-0.33-0.36-0.51-0.39-0.56C95.66,31.96,95.55,31.81,95.43,31.67"
        />
        <path
          class="st0"
          d="M100.34,36.2l-0.4-0.69c-0.01-0.02,0-0.05,0.03-0.07c0.35-0.19,0.66-0.38,0.96-0.56
			c0.03-0.02,0.06-0.02,0.07,0l0.4,0.69c0.02,0.03,0.01,0.06-0.04,0.09c-0.28,0.15-0.59,0.33-0.92,0.54
			C100.39,36.22,100.35,36.22,100.34,36.2 M99.47,34.72l-0.4-0.69c-0.01-0.02,0-0.05,0.03-0.07c0.35-0.19,0.66-0.38,0.96-0.56
			c0.03-0.02,0.06-0.02,0.07,0l0.4,0.69c0.02,0.03,0.01,0.06-0.03,0.08c-0.28,0.15-0.59,0.34-0.92,0.54
			C99.53,34.74,99.48,34.75,99.47,34.72 M96.24,34.92c0.15-0.09,0.48-0.29,1.01-0.61c0.54-0.33,0.79-0.48,0.78-0.47
			c0.03-0.02,0.05-0.02,0.06,0.01c0.01,0.01,0.03,0.06,0.08,0.16c0.05,0.11,0.09,0.18,0.12,0.23c0.02,0.04,0.06,0.1,0.13,0.19
			c0.07,0.09,0.1,0.14,0.1,0.15c0.01,0.01-0.01,0.03-0.04,0.05c0.04-0.02-0.16,0.09-0.63,0.35c-0.45,0.26-0.86,0.49-1.22,0.7
			c-0.8,0.47-1.36,0.8-1.67,0.99c-0.8,0.49-1.03,1.01-0.71,1.57c0.36,0.61,0.95,0.67,1.8,0.2c0.27-0.15,0.81-0.47,1.63-0.95
			c0.36-0.21,0.77-0.46,1.23-0.74c0.45-0.28,0.65-0.39,0.6-0.36c0.03-0.02,0.05-0.02,0.06,0.01c0.1,0.19,0.15,0.31,0.18,0.35
			c0.06,0.1,0.23,0.34,0.24,0.36c0,0.01,0,0.02-0.03,0.04c0.02-0.01-0.24,0.13-0.79,0.44c-0.54,0.3-0.88,0.5-1.04,0.6l-1.54,0.9
			c-0.75,0.44-1.42,0.62-2,0.52c-0.5-0.1-0.9-0.39-1.2-0.9c-0.62-1.05-0.22-1.99,1.17-2.81L96.24,34.92z"
        />
        <path
          class="st0"
          d="M98.94,41.32c0.12,0.26,0.31,0.43,0.57,0.48c0.21,0.05,0.44,0.02,0.7-0.1c0.53-0.24,0.68-0.63,0.43-1.18
			c-0.12-0.27-0.2-0.46-0.26-0.55c-0.01-0.03-0.03-0.03-0.06-0.01c-0.49,0.21-1.03,0.45-1.6,0.71c-0.03,0.01-0.04,0.03-0.03,0.05
			C98.73,40.84,98.82,41.03,98.94,41.32 M97.84,41.11c-0.01-0.02-0.04-0.02-0.08,0c-0.45,0.2-0.93,0.42-1.44,0.65
			c-0.5,0.23-0.73,0.34-0.67,0.32l-0.17,0.07c-0.03,0.02-0.05,0.01-0.06-0.01c0,0.01-0.07-0.22-0.13-0.36
			c-0.05-0.11-0.18-0.34-0.19-0.37c0-0.01,0-0.02,0.04-0.04c-0.01,0,0.31-0.14,0.99-0.43c0.68-0.29,1.4-0.61,2.17-0.95
			c0.51-0.23,0.98-0.44,1.39-0.63c0.29-0.13,0.56-0.25,0.81-0.37c0.21-0.1,0.31-0.15,0.3-0.14c0.03-0.02,0.06-0.02,0.07,0.01
			c0.14,0.27,0.33,0.67,0.56,1.19c0.23,0.52,0.31,0.94,0.24,1.25c-0.11,0.5-0.47,0.88-1.07,1.15c-0.72,0.32-1.41,0.23-1.85-0.15
			c-0.02-0.02-0.03-0.02-0.05,0.02c-0.08,0.25-0.34,0.57-0.77,0.96c-0.61,0.57-1.07,1-1.36,1.28c-0.03,0.03-0.05,0.03-0.06,0.01
			c0,0.01-0.02-0.05-0.07-0.17c-0.04-0.11-0.08-0.21-0.11-0.27c-0.06-0.13-0.19-0.36-0.2-0.39c0-0.01,0-0.02,0.03-0.05
			c0.27-0.21,0.71-0.59,1.31-1.15c0.32-0.29,0.5-0.51,0.55-0.66c0.04-0.13,0.02-0.29-0.07-0.49L97.84,41.11z"
        />
        <path
          class="st0"
          d="M58.63,35.33c0,0.7-0.56,1.26-1.26,1.26h-0.25c-0.69,0-1.26-0.56-1.26-1.26v-6.3c0-0.7,0.56-1.26,1.26-1.26
			h0.25c0.69,0,1.26,0.56,1.26,1.26V35.33z"
        />
        <path
          class="st0"
          d="M72.03,38.02c0.77-0.54,1.26,0.13,1.26,1.26v16.37c0,0.7-0.56,1.26-1.26,1.26H49.36
			c-1.83,0-1.73-0.85-1.26-1.26L72.03,38.02z"
        />
        <path
          class="st0"
          d="M58.65,88.73c0,0.7-0.56,1.26-1.26,1.26h-0.25c-0.69,0-1.26-0.56-1.26-1.26v-6.3c0-0.7,0.56-1.26,1.26-1.26
			h0.25c0.7,0,1.26,0.56,1.26,1.26V88.73z"
        />
        <path
          class="st0"
          d="M66.84,67.05c0.7,0,1.26,0.56,1.26,1.26v0.25c0,0.69-0.56,1.26-1.26,1.26H47.95c-0.7,0-1.26-0.56-1.26-1.26
			v-0.25c0-0.69,0.56-1.26,1.26-1.26H66.84z"
        />
        <path
          class="st0"
          d="M71.61,81.17c0.69,0,1.26,0.57,1.26,1.26v0.25c0,0.7-0.56,1.26-1.26,1.26H57.15c-0.69,0-1.26-0.56-1.26-1.26
			v-0.25c0-0.7,0.56-1.26,1.26-1.26H71.61z"
        />
        <path
          class="st0"
          d="M65.45,73.05c0-0.7,0.56-1.26,1.26-1.26h0.25c0.69,0,1.26,0.57,1.26,1.26v5.06c0,0.7-0.56,1.26-1.26,1.26
			h-0.25c-0.7,0-1.26-0.57-1.26-1.26V73.05z"
        />
        <path
          class="st0"
          d="M70.1,63.8c0-0.7,0.56-1.26,1.26-1.26h0.25c0.69,0,1.26,0.56,1.26,1.26v18.89c0,0.7-0.56,1.26-1.26,1.26
			h-0.25c-0.69,0-1.26-0.56-1.26-1.26V63.8z"
        />
        <path
          class="st0"
          d="M66.99,76.61c0.69,0,1.25,0.56,1.25,1.26v0.25c0,0.69-0.56,1.26-1.25,1.26h-23.8c-0.69,0-1.25-0.56-1.25-1.26
			v-0.25c0-0.69,0.56-1.26,1.25-1.26H66.99z"
        />
        <path
          class="st0"
          d="M41.91,63.81c0-0.69,0.56-1.24,1.26-1.24h0.25c0.69,0,1.26,0.56,1.26,1.24v14.32c0,0.69-0.56,1.24-1.26,1.24
			h-0.25c-0.69,0-1.26-0.56-1.26-1.24V63.81z"
        />
        <path
          class="st0"
          d="M46.64,68.3c0-0.69,0.56-1.24,1.26-1.24h0.25c0.69,0,1.26,0.56,1.26,1.24v4.96c0,0.68-0.56,1.24-1.26,1.24
			H47.9c-0.7,0-1.26-0.56-1.26-1.24V68.3z"
        />
        <path
          class="st0"
          d="M66.84,71.77c0.7,0,1.26,0.56,1.26,1.26v0.25c0,0.69-0.56,1.26-1.26,1.26H47.92c-0.7,0-1.26-0.56-1.26-1.26
			v-0.25c0-0.69,0.57-1.26,1.26-1.26H66.84z"
        />
        <path
          class="st0"
          d="M65.65,74.32h-0.91v0.23h0c0.39,0,0.7,0.31,0.7,0.7v0.08h0.21V74.32z"
        />
        <path
          class="st0"
          d="M65.69,76.82l0-0.91l-0.23,0v0c0,0.39-0.32,0.7-0.7,0.7h-0.08l0,0.21L65.69,76.82z"
        />
        <path
          class="st0"
          d="M70.32,81.38l0-0.91l-0.23,0v0c0,0.39-0.32,0.7-0.7,0.7h-0.08l0,0.21L70.32,81.38z"
        />
        <path
          class="st0"
          d="M44.46,76.85l0.91,0v-0.23h0c-0.39,0-0.7-0.31-0.7-0.7v-0.08h-0.21L44.46,76.85z"
        />
        <path
          class="st0"
          d="M58.43,83.73l0,0.91l0.23,0v0c0-0.39,0.32-0.7,0.7-0.7h0.08l0-0.21L58.43,83.73z"
        />
        <path
          class="st0"
          d="M43.12,56.72c-0.77,0.54-1.26-0.13-1.26-1.26V39.09c0-0.7,0.56-1.26,1.26-1.26h22.67
			c1.83,0,1.73,0.85,1.26,1.26L43.12,56.72z"
        />
      </g>
      <g>
        <defs>
          <path
            id="SVGID_4_"
            d="M28.31,51.69h1.11c1.72,0,2.62,0.52,3.29,1.98c0.58,1.25,0.85,3,0.85,5.42c0,5.24-1.34,7.55-4.34,7.55
				h-0.9V51.69z M21.64,49.45v1.75l1.9,0.82v14.34l-1.9,0.79v1.75h7.37c3.18,0,5.04-0.67,6.76-2.45c1.84-1.87,2.74-4.46,2.74-7.87
				c0-2.71-0.64-4.93-1.92-6.59c-1.43-1.89-3.15-2.54-6.79-2.54H21.64z M66.52,52.1l1.98,8.01h-4.02L66.52,52.1z M64.89,49.45
				l-5.19,16.9l-1.43,0.79v1.75h6.53v-1.75l-1.92-0.79l1.14-4.46h4.93l1.11,4.46l-1.89,0.79v1.75h8.28v-1.75l-1.4-0.79l-4.78-16.9
				H64.89z M86.1,49.45v1.75l1.93,0.82l-3.7,13.76l-3.44-13.76l1.87-0.82v-1.75h-8.28v1.75l1.46,0.82l4.69,16.87h5.42l5.16-16.87
				l1.43-0.82v-1.75H86.1z M40.53,59.82c0,3.15,0.9,5.57,2.71,7.35c1.49,1.46,3.18,2.04,5.77,2.04c2.1,0,3.47-0.23,6.79-1.19V62.5
				l1.75-0.79v-1.75h-8.28v1.75l1.84,0.79v4.52h-0.06c-1.34,0-1.78-0.09-2.59-0.44c-1.81-0.85-2.83-3.44-2.83-7.2
				c-0.03-5.25,1.9-7.96,5.57-7.96l1.11,2.65h3.5v-3.96L55.24,50c-1.28-0.26-1.98-0.41-2.13-0.44c-1.08-0.18-2.1-0.26-3.06-0.26
				C44.31,49.3,40.53,53.5,40.53,59.82"
          />
        </defs>
        <clipPath id="SVGID_5_">
          <use xlink:href="#SVGID_4_" style="overflow: visible" />
        </clipPath>

        <linearGradient
          id="SVGID_6_"
          gradientUnits="userSpaceOnUse"
          x1="-145.9839"
          y1="312.0279"
          x2="-144.9839"
          y2="312.0279"
          gradientTransform="matrix(0 -19.9054 -19.9054 0 6268.1841 -2836.6658)"
        >
          <stop offset="0" style="stop-color: #0a2c68" />
          <stop offset="0.2967" style="stop-color: #0a2c68" />
          <stop offset="0.7527" style="stop-color: #006db8" />
          <stop offset="0.7974" style="stop-color: #0168b2" />
          <stop offset="0.8583" style="stop-color: #035aa0" />
          <stop offset="0.9284" style="stop-color: #074284" />
          <stop offset="0.9835" style="stop-color: #0a2c68" />
          <stop offset="1" style="stop-color: #0a2c68" />
        </linearGradient>
        <rect x="21.64" y="49.3" class="st3" width="70.99" height="19.91" />
      </g>
    </g>
  </svg>
</template>

<style>
.st0 {
  fill: #ffffff;
}
.st1 {
  fill: #b4dcf6;
}
.st2 {
  clip-path: url(#SVGID_2_);
  fill: url(#SVGID_3_);
}
.st3 {
  clip-path: url(#SVGID_5_);
  fill: url(#SVGID_6_);
}
</style>
<script setup lang="ts"></script>
