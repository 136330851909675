import Keycloak from "keycloak-js";
import { initKeycloak, initOptions } from "@/helpers/keycloak.helper";
import store from "@/store";

const keycloak: Keycloak.KeycloakInstance =
  store.state.keycloak || new Keycloak(initOptions);

export default async function KeycloakSingleton(): Promise<Keycloak> {
  if (!keycloak.token) {
    await initKeycloak();
  }
  return keycloak;
}
