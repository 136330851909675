import { createStore } from "vuex";
import { API_ROUTES } from "@/constants/api.constants";
import apiHelper from "@/helpers/api.helper";
import state from "@/store/state";

export default createStore({
  state,
  getters: {
    getTask: (state) => {
      return state.task;
    },
  },
  mutations: {
    setTask(state, task) {
      state.task = task;
    },
    setParentTask(state, parentTask) {
      state.parentTask = parentTask;
    },
    setSearchActive(state, searchActive) {
      state.searchActive = searchActive;
    },
    setShowLightBox(state, showLightBox) {
      state.showLightBox = showLightBox;
    },
    setFormattedFiles(state, formattedFiled) {
      state.formattedFiles = formattedFiled;
    },
    setLightBoxIndex(state, lightBoxIndex) {
      state.lightBoxIndex = lightBoxIndex;
    },
    setSplashScreen(state) {
      state.showSplashScreen = false;
    },
    setShowSuccessDialog(state, showSuccessDialog) {
      state.showSuccessDialog = showSuccessDialog;
    },
    successDialogText(state, successDialogText) {
      state.successDialogText = successDialogText;
    },
    setKeycloak(state, keycloak) {
      state.keycloak = keycloak;
    },
  },
  actions: {
    async loadTaskById({ commit }, { taskId }) {
      const url = API_ROUTES.GET_TASK_BY_ID.replace(":taskId", taskId);

      const { data } = await apiHelper.get(url);

      commit("setTask", data);
    },

    async loadParentTaskById({ commit }, { taskId }) {
      const url = API_ROUTES.GET_TASK_BY_ID.replace(":taskId", taskId);

      const { data } = await apiHelper.get(url);

      commit("setParentTask", data);
    },

    setShowLightBox({ commit }, showLightBox) {
      if (!showLightBox) {
        commit("setLightBoxIndex", -1);
        commit("setFormattedFiles", []);
      }
      commit("setShowLightBox", showLightBox);
    },

    setFormattedFiles({ commit }, formattedFiled) {
      commit("setFormattedFiles", formattedFiled);
    },

    setLightBoxIndex({ commit }, lightBoxIndex) {
      commit("setLightBoxIndex", lightBoxIndex);
    },
    startSplashScreen({ commit }) {
      setTimeout(() => {
        commit("setSplashScreen");
      }, 2000);
    },
    showSuccessDialog({ commit, dispatch }, successDialogText) {
      commit("setShowSuccessDialog", true);
      commit("successDialogText", successDialogText);
      setTimeout(() => {
        dispatch("closeSuccessDialog");
      }, 3000);
    },
    closeSuccessDialog({ commit }) {
      commit("setShowSuccessDialog", false);
      commit("successDialogText", "");
    },
  },
  modules: {},
});
