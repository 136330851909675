<template>
  <v-dialog width="500" v-model="showSuccessDialog">
    <v-card class="border-radius-round bg-ice-blue pa-5">
      <v-card-text>
        <h2
          class="text-center px-5 success-dialog__text"
          v-html="successDialogText"
        />
      </v-card-text>

      <v-card-actions>
        <div class="w-100 d-flex justify-end"></div>
        <v-btn
          @click="showSuccessDialog = false"
          class="font-weight-bold text-main-blue text-capitalize"
        >
          Schließen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  computed: {
    showSuccessDialog: {
      get() {
        return this.$store.state.showSuccessDialog;
      },
      set(value) {
        if (!value) {
          this.$store.dispatch("closeSuccessDialog", value);
        }
      },
    },
    successDialogText() {
      return this.$store.state.successDialogText;
    },
  },
};
</script>

<style>
.success-dialog__text {
  font-size: 1.7rem;
  font-weight: 300;
  line-height: 1.5;
}
</style>
