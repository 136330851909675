import apiHelper from "@/helpers/api.helper";
import { API_ROUTES } from "@/constants/api.constants";
import axios from "axios";

export async function initializePushNotifications() {
  if (isIos()) {
    return;
  }

  if (Notification.permission === "default") {
    const permissions = await Notification.requestPermission();
    if (permissions === "granted") {
      regWorker().catch((err) => console.error(err));
    }
  } else if (Notification.permission === "granted") {
    regWorker().catch((err) => console.error(err));
  } else {
    return;
  }
}

function isIos() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

async function regWorker() {
  // (B1) YOUR PUBLIC KEY - CHANGE TO YOUR OWN!
  const publicKey =
    "BLzp74C51iPrOXLSFXLezi0Hci-Nx6Do8myio05iTvtdksaXHeAXIaAom1d0aUI_fb7-I9C08UGbqfaX_Ugx-bM";

  // (B2) REGISTER SERVICE WORKER
  await navigator.serviceWorker.register(
    "push-notification-service-worker.js",
    { scope: "/" }
  );

  // (B3) SUBSCRIBE TO PUSH SERVER
  const reg = await navigator.serviceWorker.ready;
  try {
    const { data: user } = await apiHelper.get(API_ROUTES.GET_ACCOUNT);

    const subscription = JSON.parse(
      JSON.stringify(
        await reg.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: publicKey,
        })
      )
    );

    await axios.post(`${process.env.VUE_APP_PUSH_SERVICE}/save-subscription`, {
      ...subscription,
      user: user.profile.id,
    });
  } catch (error) {
    console.error(error);
  }
}
