/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import KeycloakSingleton from "@/singletons/keycloak.singleton";
import axiosRetry from "axios-retry";
import { Notification } from "@/interfaces/notification.interface";
import router from "@/router";
import { API_ROUTES } from "@/constants/api.constants";
import { LearningTaskFull } from "@/interfaces/learning-task.interface";
import store from "@/store";
import { LearningTaskStatus } from "@/interfaces/learning-task-status.interface";
import { authenticateAgainstKeycloak } from "@/helpers/keycloak.helper";

const CONTENT_TYPE = "application/json";
const TIMEOUT = 30000;
export default {
  keycloak: await KeycloakSingleton(),

  async getInstance() {
    axiosRetry(axios, { retries: 3 });
    const token = localStorage.getItem("token");

    const instance = axios.create({
      baseURL: process.env.VUE_APP_AXIOS_BASE_URL,
      timeout: TIMEOUT,
      headers: {
        "Content-Type": CONTENT_TYPE,
        common: {
          Authorization: `Bearer ${token}`,
        },
      },
    });
    instance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        if ([403, 401].includes(error.response.status)) {
          await authenticateAgainstKeycloak();
        }
        return error;
      }
    );
    return instance;
  },

  async post(route: string, payload?: object | null): Promise<any | void> {
    const instance = await this.getInstance();
    return instance.post(route, payload).catch((error) => {
      if (error?.response?.status === 401) {
        router.go(0);
        return;
      }
      throw error;
    });
  },
  async patch(route: string, payload: object | null): Promise<any | void> {
    const instance = await this.getInstance();
    return instance.patch(route, payload).catch((error) => {
      if (error?.response?.status === 401) {
        router.go(0);
        return;
      }
      throw error;
    });
  },
  async put(route: string, payload: object | null): Promise<any | void> {
    const instance = await this.getInstance();
    return instance.put(route, payload).catch((error) => {
      if (error?.response?.status === 401) {
        router.go(0);
        return;
      }
      throw error;
    });
  },
  async delete(route: string): Promise<any | void> {
    const instance = await this.getInstance();
    return instance.delete(route).catch((error) => {
      if (error?.response?.status === 401) {
        router.go(0);
        return;
      }
      throw error;
    });
  },
  async get(route: string): Promise<any | void> {
    const instance = await this.getInstance();
    return instance.get(route).catch((error) => {
      if (error?.response?.status === 401) {
        router.go(0);
        return;
      }
      throw error;
    });
  },

  async getNotifications(user: string): Promise<Notification[]> {
    const { data } = await axios.get(
      `${process.env.VUE_APP_PUSH_SERVICE}/notifications`,
      {
        params: {
          user,
        },
      }
    );
    return data;
  },

  async deleteNotification(id: string): Promise<void> {
    await axios.delete(`${process.env.VUE_APP_PUSH_SERVICE}/notifications`, {
      params: {
        id,
      },
    });
  },
  async toggleFavorite(task: LearningTaskFull): Promise<void> {
    const url = API_ROUTES.TOGGLE_FAVORITE.replace(
      ":taskId",
      task.id.toString()
    );

    if (!task.taskStatus) {
      task.taskStatus = { favorite: false } as LearningTaskStatus;
    }

    if (task.taskStatus?.favorite) {
      await this.delete(url);
    } else {
      await this.post(url);
    }

    task.taskStatus.favorite = !task.taskStatus.favorite;

    store.commit("setTask", task);
  },
};
