import { LearningTaskFull } from "@/interfaces/learning-task.interface";
import Keycloak from "keycloak-js";

const state: {
  task: LearningTaskFull | null;
  parentTask: LearningTaskFull | null;
  searchActive: boolean;
  showLightBox: boolean;
  formattedFiles: Array<{ src: string; title: string }>;
  lightBoxIndex: number;
  showSplashScreen: boolean;
  showSuccessDialog: boolean;
  successDialogText: string;
  keycloak: Keycloak.KeycloakInstance | null;
} = {
  task: null,
  parentTask: null,
  searchActive: false,
  showLightBox: false,
  formattedFiles: [],
  lightBoxIndex: -1,
  showSplashScreen: true,
  showSuccessDialog: false,
  successDialogText: "",
  keycloak: null,
};

export default state;
